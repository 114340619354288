@import "mixins";

app-root {

  app-main {

    &.mobile {

      app-dashboard,
      app-configuration,
      app-orders,
      app-products,
      app-media-library,
      app-asset-editor,
      app-communities {
        padding: 0 20px 0 20px;
        width: 100%;
        height: calc(100% - 61px);
      }

      app-dashboard,
      app-configuration {
        padding-bottom: 0 !important;

        .table-header-container {
          display: none !important;
        }

        .widget-container {
          flex-flow: column nowrap !important;
          margin: 0 !important;
          height: 100% !important;
          padding-bottom: 20px !important;
        }

      }

      .breadcrumb {
        margin: 0 !important;
      }

      mat-tab-group {
        display: flex;
        flex-direction: column !important;

        &.single-tabbed .mat-tab-header {
          display: none;
        }

        mat-tab-header {
          width: 100% !important;
          border: none !important;

          .mat-tab-header-pagination {
            display: none !important;
          }

          .mat-tab-label-container {
            overflow-x: auto !important;

            .mat-tab-labels {
              flex-direction: row !important;
              overflow-x: scroll !important;

              &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: var(--gray-color-03);
              }

              .mat-tab-label {
                min-width: 0;
                padding: 0;
                margin-right: 20px;

                &:last-of-type {
                  margin-right: 0;
                }

                .mat-tab-label-content {
                  position: relative !important;
                  color: var(--medium-gray);
                  text-transform: uppercase;
                }

                &.mat-tab-label-active {

                  .mat-tab-label-content {
                    color: var(--primary-color);
                  }

                }

              }

            }

          }


        }

        mat-ink-bar {
          display: block !important;
          height: 1px;
          background-color: var(--primary-color) !important;
        }

        .section-title {
          display: none;
        }

        .mat-tab-body-wrapper {
          //overflow-y: auto !important;

          .mat-tab-body-content {
            //overflow: hidden !important;
          }

        }

      }
      data-table-sm-header {
        order: 2;

        .table-header-container {
          padding: 10px 0 !important;
          justify-content: flex-end !important;

          .optional-controls-container {
            margin-right: -10px;

            .table-settings-button {
              background-color: var(--button-blue-color);
              border-radius: 100%;
              color: var(--white-color);
            }

          }

        }

      }

      data-table-sm {

        .mat-column-options {
          display: none;
        }

        remote-data-table-tab {
          order: 1;
        }

        .table-container {
          order: 3;

          table {
            display: flex;
            flex-direction: column;

            thead {
              display: none;
            }

            tbody {
              display: flex;
              flex-direction: column;

              tr {
                position: relative;
                display: flex;
                flex-flow: row wrap;
                border-bottom: 1px solid var(--radio-border-color);
                padding: 15px 0;
                height: auto !important;

                &:last-of-type,
                &:nth-last-child(-n+2) {
                  border-bottom: 0;
                }

                td {
                  border-bottom: 0;
                  padding: 0 !important;
                  height: auto;
                  min-height: 0;
                  display: flex;
                  align-items: center;
                }

                td.cdk-column-checkbox,
                td.cdk-column-menu,
                td.cdk-column-dieta,
                td.cdk-column-allergenes {
                  display: none;
                }

                td.cdk-column-name {
                  width: 50%;
                }

                td.cdk-column-price {
                  width: 50%;
                  justify-content: flex-end;
                  font-weight: var(--font-semibold);

                  &::before {
                    position: relative;
                    content: "€";
                    @include font-style(10px, var(--font-regular), inherit, inherit, inherit);
                    margin-right: 5px;
                  }

                }

                td.cdk-column-order {
                  width: 50%;
                }

                td.cdk-column-updateDate {
                  width: 50%;
                  justify-content: flex-end;
                }

                td.cdk-column-options {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100% !important;
                  height: 100%;
                  opacity: 0;
                  z-index: 100;
                }

              }

            }

          }

        }

        .grid-container {
          order: 4;
        }

        mat-paginator {
          order: 5;
          height: 60px;
          position: fixed;
          bottom: 0;
          z-index: 997;
          width: calc(100% - 40px);

          .mat-paginator-container {
            padding: 0;
            justify-content: center;

            .mat-paginator-page-size {
              display: none;
            }

            .mat-paginator-range-actions {
              @include flex-center;
              width: 100%;

              .mat-paginator-navigation-previous {
                position: absolute;
                left: 0;
              }

              .mat-paginator-navigation-next {
                position: absolute;
                right: 0;
              }

            }

          }

        }

      }

      product-filters {
        display: flex;
        margin-left: -20px;
        margin-right: -20px;
        padding: 0;

        .product-filters-container {
          height: auto !important;
          max-height: unset !important;
          flex-direction: column !important;
          width: 100vw !important;
          padding: 0 20px !important;
          align-items: unset !important;
          margin-left: 0 !important;

          mat-label {
            padding: 0 !important;
            text-align: center !important;
            margin-bottom: 10px;
            margin-top: 20px;
          }

          .select-container {
            margin-bottom: 10px;
            align-items: center;

            mat-label {
              width: 33.333%;
              text-align: left !important;
            }

            mat-select {
              flex: 1;
              max-width: unset !important;
              padding: 10px !important;
            }

          }

          mat-slide-toggle {
            margin-bottom: 10px !important;
          }

          .action-container {
            margin-bottom: 20px;
          }

        }

      }

      .spinner-container {
        width: calc(100%) !important;
      }

      app-product-editor {
        padding: 0 !important;

        .working-community {
          display: none !important;
        }

      }

      .editor-header,
      .asset-editor-header {
        margin-right: 0 !important;
        padding-top: 0 !important;

        .select-container {

          .mat-select-trigger {
            height: 40px;
          }

        }

        .action-container {
          padding: 0 !important;
          justify-content: flex-end !important;

          app-icon {
            padding: 0 0 0 20px !important;
            min-width: unset !important;

            &:first-of-type {
              padding-left: 0 !important;
            }

          }

        }

      }

      .product-editor-container {
        padding-top: 20px !important;

        .base-product-editor-tab-container {
          display: flex;
          flex-flow: row wrap;
          padding: 0 !important;
        }

      }

      base-asset-editor {

        .asset-editor-container {
          width: 100% !important;
          height: auto !important;
          flex-direction: column !important;
          padding: 0 !important;
          margin-top: 20px !important;
        }

      }

      .preview-container {
        width: 100% !important;
        height: 250px !important;
        margin-bottom: 20px !important;
        max-width: unset !important;
      }

      .form-field-container {
        width: 100% !important;
        padding: 0 !important;
        right: unset !important;
        border: 0 !important;

        > * {
          padding: 0 !important;
          margin-bottom: 20px !important;
        }

        .row {
          flex-direction: column !important;
          width: 100%;
          margin-top: 0 !important;

          .form-field:last-of-type {
            margin-bottom: 0 !important;
          }

        }

        .file-container {
          width: 100% !important;
          height: auto !important;
          max-width: unset !important;
          margin-bottom: 20px !important;

          .form-file {
            width: 100% !important;
            height: 250px !important;
            max-width: unset !important;

            .qrcode {
              @include flex-center;
            }

          }

          label {
            margin-bottom: 5px;
          }

        }

        .form-fields-container {
          width: 100% !important;
          padding: 0 !important;
        }

        .form-field {
          padding: 0 !important;
          width: 100% !important;
          margin-bottom: 20px !important;

          label {
            margin-bottom: 5px;

            &.main {
              margin-bottom: 0 !important;
            }

          }

          input {
            margin-top: 0 !important;
          }

          > app-icon {
            bottom: 0;
            height: 42px !important;
            background-color: var(--form-background);
            border-radius: 10px;
          }

          > .row {
            margin-bottom: 20px;
          }

        }

        .chips-container {
          min-height: 150px !important;

          app-icon {
            height: 30px !important;
          }

        }

        app-geocode {

          .geocode-container {
            display: flex !important;

            > div:first-of-type {
              width: unset !important;
              margin-right: 20px !important;
              flex: 1 !important;
            }

            > div.actions {
              flex: unset !important;
              width: 40px !important;

              app-icon {
                font-size: 23px !important;
                background-color: var(--transparent-color) !important;
                height: 23px !important;
                width: 23px !important;
                margin-top: 20px;
              }

            }

          }

        }

      }

      .base-values-container {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        padding: 0;

        .base-image {
          width: 100%;

          file-form-field.file-input {
            width: 100% !important;
            max-width: unset !important;
            height: 500px !important;
            padding: 0 !important;
            margin-bottom: 20px !important;
          }

        }

        .form-field-container {
          width: 100% !important;

          > * {
            margin-top: 0 !important;
            padding: 0 !important;
            margin-bottom: 20px !important;
          }

          datalean-editor {

            ejs-richtexteditor {
              height: 300px !important;
            }

          }

          container-field {
            display: flex;
            flex-direction: column;

            > label {
              margin-bottom: 10px;
            }

            container-card {

              .base-values-container {
                overflow: hidden;

                product-field-editor {
                  margin-top: 0 !important;
                  margin-bottom: 20px !important;
                }

              }

            }

          }

        }

      }

      .customizations-container {
        width: 100%;
        float: none;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin-bottom: 20px;

        > * {
          padding: 0 !important;
          margin-bottom: 20px;
        }

      }

      .categories-container {
        width: 100%;
        float: none;
        display: flex;
        flex-direction: column;
        padding: 0;
      }

      drag-and-drop-zone {
        display: none !important;
      }

      app-media-library {

        td.cdk-column-name,
        td.cdk-column-lastModifiedDate,
        td.cdk-column-entityType {
          width: 100% !important;
        }

        td.cdk-column-fullPath,
        td.cdk-column-owner,
        td.cdk-column-elementsNumber,
        td.cdk-column-status {
          display: none !important;
        }

      }

      app-asset-editor {

        .asset-editor-header {
          position: fixed;
          background-color: var(--white-color);
          z-index: 100;
          width: calc(100% - 40px);
        }

        base-asset-editor {
          height: auto;
          overflow-y: auto;
          margin-top: 50px;

          > .container {
            display: none;
          }

        }

      }

      app-communities {

        td.cdk-column-name,
        td.cdk-column-category {
          width: 100% !important;
        }

        community-editor-tab {
          margin-top: 20px !important;
        }

      }

      app-community-editor {

        .mat-tab-body-content {
          overflow: hidden !important;
        }

      }

      app-time-range,
      app-time-range-interval {

        > .row {
          margin-bottom: 20px !important;

          .file-container {
            display: none !important;
          }

          > .column {
            width: 100% !important;
            flex-direction: row !important;
            justify-content: space-between !important;
            margin-bottom: 20px !important;

            > .row {
              flex-direction: row !important;
              justify-content: space-between !important;

              > .column {
                padding: 0 !important;
                width: calc(50% - 10px) !important;
              }

            }

          }

        }

      }

      app-draggable-tree {
        padding: 0 !important;

        .tree-container {

          .tree-header {
            justify-content: flex-end !important;
            margin-bottom: 20px;

            > app-icon {
              margin-left: 10px;
            }

          }

          mat-tree {

            mat-tree-node {
              padding: 10px 0;

              mat-checkbox {
                display: none !important;
              }

              app-icon.add,
              app-icon.remove {
                font-size: 16px !important;
                margin-left: 20px;
              }

            }

          }

        }

      }

      customization-group-draggable-tree {

        > .main-container {
          height: auto !important;
        }

      }

    }

  }

}

app-modal {

  @media only screen and (max-width: 540px) {
    width: 100%;
    height: 100%;
    border-radius: 0 !important;
    border-bottom: 0 !important;

    div.select-file-for-import-container {
      width: calc(100% - 20px) !important;
    }

    modal-header {
      width: 100% !important;

      app-icon {
        padding: 5px 0 5px 30px !important;
      }

    }

    form {
      width: 100% !important;

      .file-container2 {

        div {
          width: 100% !important;
        }

      }

      .select-container {

        mat-select {
          width: 100% !important;
          height: 40px !important;
        }

      }

      .email-container {

        input {
          width: 100% !important;
        }

      }

    }

    div.media-library-container,
    div.user-selection-container,
    div.category-selection-container,
    div.folder-creation-container,
    div.simple-dialog-container,
    div.message-container {
      width: 100% !important;
      //height: auto !important;
      min-width: 0 !important;
      display: flex !important;
      flex-direction: column !important;
      min-height: 100vh !important;
      padding: 0 !important;

      modal-header {
        padding: 20px;
        //position: fixed;
        background: var(--white-color);
        z-index: 10;
        height: 64px;
        box-sizing: border-box;
      }

      .table-container,
      .main-container {
        padding: 0px 20px 0 20px !important;
        overflow-y: auto;
        flex-direction: column;
        max-height: unset;
        height: 100%;

        .asset-editor-container {
          padding-top: 0 !important;
          padding-right: 0 !important;
          margin-bottom: 20px;
          margin-top: 20px;
          flex-direction: column;

          .preview-container {
            width: 100% !important;
            max-width: unset !important;
            height: 200px !important;
            margin-bottom: 20px !important;
          }

          .form-field-container {

            .form-field {
              padding: 0 !important;
              margin-bottom: 20px;

              > app-icon {
                bottom: 0;
                height: 42px !important;
              }

            }

          }

        }

        .path,
        .container {
          display: none !important;
        }

        > .chips-container {
          position: relative !important;
          left: unset !important;
          height: 250px !important;
          width: 100% !important;
          margin-bottom: 20px;
          top: unset !important;
          flex: unset !important;
        }

        > .table-container,
        > .tree-container {
          position: relative !important;
          right: unset !important;
          height: auto !important;
          width: 100% !important;
          flex: unset !important;
        }

        mat-tab-group {

          .mat-tab-label {

            &.mat-tab-label-active {
              color: var(--primary-color) !important;
            }

            .mat-tab-label-content {
              text-transform: uppercase !important;
            }

          }

        }

        data-table-sm-header {

          .table-header-container {
            padding: 10px 0 !important;

            .optional-controls-container {
              justify-content: flex-end;

              search-field {
                justify-content: flex-end;
              }

            }

          }

        }

        data-table-sm {

          .breadcrumb {
            margin: 0 !important;
            border-bottom: 1px solid var(--light-gray);
          }

          mat-grid-list {
            > div {
              display: flex;
              flex-flow: row wrap;

              mat-grid-tile {
                position: relative !important;
                width: calc(50% - 10px) !important;
                margin-right: 20px !important;
                margin-top: 20px !important;
                //height: auto !important;
                left: unset !important;
                top: unset !important;

                &:nth-of-type(2n) {
                  margin-right: 0 !important;
                }

                mat-card {
                  padding: 0 !important;

                  mat-card-header {
                    margin-top: 5px !important;

                    .mat-card-header-text {
                      margin: 0 !important;

                      mat-card-title {
                        font-size: 12px !important;
                      }

                    }

                  }

                }

              }

            }

          }

          mat-paginator {
            padding: 0 10px;

            .mat-paginator-container {
              padding: 0;
              justify-content: center;

              .mat-paginator-page-size {
                display: none;
              }

              .mat-paginator-range-actions {
                @include flex-center;
                width: 100%;

                .mat-paginator-navigation-previous {
                  position: absolute;
                  left: 0;
                }

                .mat-paginator-navigation-next {
                  position: absolute;
                  right: 0;
                }

              }

            }

          }

        }

      }

    }

  }

}
