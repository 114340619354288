/* --- SEGMA --- */
@font-face {
  font-family: 'Segma';
  src: url('segma/Segma-Thin.eot');
  src: url('segma/Segma-Thin.eot?#iefix') format('embedded-opentype'),
  url('segma/Segma-Thin.woff2') format('woff2'),
  url('segma/Segma-Thin.woff') format('woff'),
  url('segma/Segma-Thin.ttf') format('truetype'),
  url('segma/Segma-Thin.svg#Segma-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Segma';
  src: url('segma/Segma-ExtraLight.eot');
  src: url('segma/Segma-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('segma/Segma-ExtraLight.woff2') format('woff2'),
  url('segma/Segma-ExtraLight.woff') format('woff'),
  url('segma/Segma-ExtraLight.ttf') format('truetype'),
  url('segma/Segma-ExtraLight.svg#Segma-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Segma';
  src: url('segma/Segma-Light.eot');
  src: url('segma/Segma-Light.eot?#iefix') format('embedded-opentype'),
  url('segma/Segma-Light.woff2') format('woff2'),
  url('segma/Segma-Light.woff') format('woff'),
  url('segma/Segma-Light.ttf') format('truetype'),
  url('segma/Segma-Light.svg#Segma-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Segma';
  src: url('segma/Segma-Regular.eot');
  src: url('segma/Segma-Regular.eot?#iefix') format('embedded-opentype'),
  url('segma/Segma-Regular.woff2') format('woff2'),
  url('segma/Segma-Regular.woff') format('woff'),
  url('segma/Segma-Regular.ttf') format('truetype'),
  url('segma/Segma-Regular.svg#Segma-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Segma';
  src: url('segma/Segma-Medium.eot');
  src: url('segma/Segma-Medium.eot?#iefix') format('embedded-opentype'),
  url('segma/Segma-Medium.woff2') format('woff2'),
  url('segma/Segma-Medium.woff') format('woff'),
  url('segma/Segma-Medium.ttf') format('truetype'),
  url('segma/Segma-Medium.svg#Segma-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Segma';
  src: url('segma/Segma-SemiBold.eot');
  src: url('segma/Segma-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('segma/Segma-SemiBold.woff2') format('woff2'),
  url('segma/Segma-SemiBold.woff') format('woff'),
  url('segma/Segma-SemiBold.ttf') format('truetype'),
  url('segma/Segma-SemiBold.svg#Segma-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Segma';
  src: url('segma/Segma-Bold.eot');
  src: url('segma/Segma-Bold.eot?#iefix') format('embedded-opentype'),
  url('segma/Segma-Bold.woff2') format('woff2'),
  url('segma/Segma-Bold.woff') format('woff'),
  url('segma/Segma-Bold.ttf') format('truetype'),
  url('segma/Segma-Bold.svg#Segma-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Segma';
  src: url('segma/Segma-Black.eot');
  src: url('segma/Segma-Black.eot?#iefix') format('embedded-opentype'),
  url('segma/Segma-Black.woff2') format('woff2'),
  url('segma/Segma-Black.woff') format('woff'),
  url('segma/Segma-Black.ttf') format('truetype'),
  url('segma/Segma-Black.svg#Segma-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* --- DATALEAN 2023 --- */
/*  prima era font-family: "Datalean 2020"; */
@font-face {
  font-family: 'DataLean Font Icon';
  src:  url('datalean-2023/icomoon.eot?40co4b');
  src:  url('datalean-2023/icomoon.eot?40co4b#iefix') format('embedded-opentype'),
        url('datalean-2023/icomoon.woff2?40co4b') format('woff2'),
        url('datalean-2023/icomoon.ttf?40co4b') format('truetype'),
        url('datalean-2023/icomoon.woff?40co4b') format('woff'),
        url('datalean-2023/icomoon.svg?40co4b#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="adc-icn-"],
[class*=" adc-icn-"] {
  font-family: 'DataLean Font Icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.adc-icn-add::before {
  content: "\61"; /* a */
}
.adc-icn-arrow::before {
  content: "\62"; /* b */
}
.adc-icn-categories::before {
  content: "\63"; /* c */
}
.adc-icn-check::before {
  content: "\64"; /* d */
}
.adc-icn-checkbox-off::before {
  content: "\65"; /* e */
}
.adc-icn-checkbox-on::before {
  content: "\66"; /* f */
}
.adc-icn-close::before {
  content: "\67"; /* g */
}
.adc-icn-configuration::before {
  content: "\68"; /* h */
}
.adc-icn-contents::before {
  content: "\69"; /* i */
}
.adc-icn-copy::before {
  content: "\6a"; /* j */
}
.adc-icn-dashboard::before {
  content: "\6b"; /* k */
}
.adc-icn-delete::before {
  content: "\6c"; /* l */
}
.adc-icn-document::before {
  content: "\6d"; /* m */
}
.adc-icn-download::before {
  content: "\6e"; /* n */
}
.adc-icn-dropdown::before {
  content: "\6f"; /* o */
}
.adc-icn-edit::before {
  content: "\70"; /* p */
}
.adc-icn-filter::before {
  content: "\71"; /* q */
}
.adc-icn-general-description::before {
  content: "\72"; /* r */
}
.adc-icn-grid::before {
  content: "\73"; /* s */
}
.adc-icn-gotomap::before {
  content: "\74"; /* t */
}
.adc-icn-info::before {
  content: "\75"; /* u */
}
.adc-icn-help::before {
  content: "\76"; /* v */
}
.adc-icn-integrations::before {
  content: "\77"; /* w */
}
.adc-icn-list::before {
  content: "\78"; /* x */
}
.adc-icn-mappings::before {
  content: "\79"; /* y */
}
.adc-icn-media-library::before {
  content: "\7a"; /* z */
}
.adc-icn-more::before {
  content: "\41"; /* A */
}
.adc-icn-notifications::before {
  content: "\42"; /* B */
}
.adc-icn-organizations::before {
  content: "\43"; /* C */
}
.adc-icn-passepartout::before {
  content: "\44"; /* D */
}
.adc-icn-password::before {
  content: "\45"; /* E */
}
.adc-icn-preview::before {
  content: "\46"; /* F */
}
.adc-icn-products::before {
  content: "\47"; /* G */
}
.adc-icn-search::before {
  content: "\48"; /* H */
}
.adc-icn-structures::before {
  content: "\49"; /* I */
}
.adc-icn-update::before {
  content: "\4a"; /* J */
}
.adc-icn-users::before {
  content: "\4b"; /* K */
}
.adc-icn-video::before {
  content: "\4c"; /* L */
}
.adc-icn-video-outline::before {
  content: "\4d"; /* M */
}
.adc-icn-table::before {
  content: "\4e"; /* N */
}
.adc-icn-print::before {
  content: "\4f"; /* O */
}
.adc-icn-orders::before {
  content: "\50"; /* P */
}
.adc-icn-calendar::before {
  content: "\51"; /* Q */
}
.adc-icn-communications::before {
  content: "\52"; /* R */
}
.adc-icn-communities::before {
  content: "\53"; /* S */
}
.adc-icn-users-1::before {
  content: "\54"; /* T */
}
.adc-icn-upload::before {
  content: "\55"; /* U */
}
.adc-icn-user-datalean::before {
  content: "\56"; /* V */
}
.adc-icn-survey::before {
  content: "\57"; /* W */
}
.adc-icn-geologalize::before {
  content: "\58"; /* X */
}
.adc-icn-groups::before {
  content: "\59"; /* Y */
}
.adc-icn-delivery::before {
  content: "\5a"; /* Z */
}
.adc-icn-dishes::before {
  content: "\30"; /* 0 */
}
.adc-icn-hours::before {
  content: "\31"; /* 1 */
}
.adc-icn-payment::before {
  content: "\32"; /* 2 */
}
.adc-icn-easyorder::before {
  content: "\33"; /* 3 */
}
.adc-icn-restaurant::before {
  content: "\34"; /* 4 */
}
.adc-icn-orders-1::before {
  content: "\35"; /* 5 */
}
.adc-icn-configuration-1::before {
  content: "\36"; /* 6 */
}
.adc-icn-flag::before {
  content: "\37"; /* 7 */
}
.adc-icn-question::before {
  content: "\38"; /* 8 */
}
.adc-icn-lms::before {
  content: "\39"; /* 9 */
}
.adc-icn-calendar2::before {
  content: "\7c"; /* | */
}
.adc-icn-reservations::before {
  content: "\5c"; /* \ */
}
.adc-icn-workflow::before {
  content: "\22"; /* " */
}
.adc-icn-versioning::before {
  content: "\a3"; /* £ */
}

/* --- SMARTCO --- */
@font-face {
  font-family: "Smart Community";
  src: url("smartCo/smart-community.eot");
  src: url("smartCo/smart-community.eot?#iefix") format("embedded-opentype"),
      url("smartCo/smart-community.woff") format("woff"),
      url("smartCo/smart-community.ttf") format("truetype"),
      url("smartCo/smart-community.svg#smart-community") format("svg");
  font-weight: normal;
  font-style: normal;
}

[data-icon]::before {
  font-family: "Smart Community" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="smart-community-icon-"]::before,
[class*=" smart-community-icon-"]::before {
  font-family: "Smart Community" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.smart-community-con-login::before {
  content: "\61";
}
.smart-community-icon-communications::before {
  content: "\63";
}
.smart-community-icon-search::before {
  content: "\64";
}
.smart-community-icon-chat::before {
  content: "\65";
}
.smart-community-icon-profile::before {
  content: "\66";
}
.smart-community-icon-categories::before {
  content: "\67";
}
.smart-community-icon-dates::before {
  content: "\68";
}
.smart-community-icon-check::before {
  content: "\69";
}
.smart-community-icon-language::before {
  content: "\6b";
}
.smart-community-icon-logout::before {
  content: "\62";
}
.smart-community-icon-attachment::before {
  content: "\6a";
}
.smart-community-icon-arrow::before {
  content: "\6c";
}
.smart-community-icon-cockpit::before {
  content: "\6d";
}
.smart-community-icon-promo::before {
  content: "\6e";
}
.smart-community-icon-change-password::before {
  content: "\70";
}
.smart-community-icon-profile-2::before {
  content: "\6f";
}
.smart-community-icon-help::before {
  content: "\71";
}
.smart-community-icon-tutorial::before {
  content: "\72";
}
.smart-community-icon-settings::before {
  content: "\73";
}
