@charset "UTF-8";
@import './assets/fonts/fonts.css';
/* GENERALS */
/* SPECIFIC */
.disable-block {
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 999;
  background: var(--white-color);
  opacity: 0.7;
  bottom: 0;
  left: 0;
}

/* NUOVI DAL 2023 */
/* GENERALS */
/* SPECIFIC */
.disable-block {
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 999;
  background: var(--white-color);
  opacity: 0.7;
  bottom: 0;
  left: 0;
}

/* NUOVI DAL 2023 */
app-root app-main.mobile app-dashboard,
app-root app-main.mobile app-configuration,
app-root app-main.mobile app-orders,
app-root app-main.mobile app-products,
app-root app-main.mobile app-media-library,
app-root app-main.mobile app-asset-editor,
app-root app-main.mobile app-communities {
  padding: 0 20px 0 20px;
  width: 100%;
  height: calc(100% - 61px);
}
app-root app-main.mobile app-dashboard,
app-root app-main.mobile app-configuration {
  padding-bottom: 0 !important;
}
app-root app-main.mobile app-dashboard .table-header-container,
app-root app-main.mobile app-configuration .table-header-container {
  display: none !important;
}
app-root app-main.mobile app-dashboard .widget-container,
app-root app-main.mobile app-configuration .widget-container {
  flex-flow: column nowrap !important;
  margin: 0 !important;
  height: 100% !important;
  padding-bottom: 20px !important;
}
app-root app-main.mobile .breadcrumb {
  margin: 0 !important;
}
app-root app-main.mobile mat-tab-group {
  display: flex;
  flex-direction: column !important;
}
app-root app-main.mobile mat-tab-group.single-tabbed .mat-tab-header {
  display: none;
}
app-root app-main.mobile mat-tab-group mat-tab-header {
  width: 100% !important;
  border: none !important;
}
app-root app-main.mobile mat-tab-group mat-tab-header .mat-tab-header-pagination {
  display: none !important;
}
app-root app-main.mobile mat-tab-group mat-tab-header .mat-tab-label-container {
  overflow-x: auto !important;
}
app-root app-main.mobile mat-tab-group mat-tab-header .mat-tab-label-container .mat-tab-labels {
  flex-direction: row !important;
  overflow-x: scroll !important;
}
app-root app-main.mobile mat-tab-group mat-tab-header .mat-tab-label-container .mat-tab-labels::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--gray-color-03);
}
app-root app-main.mobile mat-tab-group mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label {
  min-width: 0;
  padding: 0;
  margin-right: 20px;
}
app-root app-main.mobile mat-tab-group mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label:last-of-type {
  margin-right: 0;
}
app-root app-main.mobile mat-tab-group mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label .mat-tab-label-content {
  position: relative !important;
  color: var(--medium-gray);
  text-transform: uppercase;
}
app-root app-main.mobile mat-tab-group mat-tab-header .mat-tab-label-container .mat-tab-labels .mat-tab-label.mat-tab-label-active .mat-tab-label-content {
  color: var(--primary-color);
}
app-root app-main.mobile mat-tab-group mat-ink-bar {
  display: block !important;
  height: 1px;
  background-color: var(--primary-color) !important;
}
app-root app-main.mobile mat-tab-group .section-title {
  display: none;
}
app-root app-main.mobile data-table-sm-header {
  order: 2;
}
app-root app-main.mobile data-table-sm-header .table-header-container {
  padding: 10px 0 !important;
  justify-content: flex-end !important;
}
app-root app-main.mobile data-table-sm-header .table-header-container .optional-controls-container {
  margin-right: -10px;
}
app-root app-main.mobile data-table-sm-header .table-header-container .optional-controls-container .table-settings-button {
  background-color: var(--button-blue-color);
  border-radius: 100%;
  color: var(--white-color);
}
app-root app-main.mobile data-table-sm .mat-column-options {
  display: none;
}
app-root app-main.mobile data-table-sm remote-data-table-tab {
  order: 1;
}
app-root app-main.mobile data-table-sm .table-container {
  order: 3;
}
app-root app-main.mobile data-table-sm .table-container table {
  display: flex;
  flex-direction: column;
}
app-root app-main.mobile data-table-sm .table-container table thead {
  display: none;
}
app-root app-main.mobile data-table-sm .table-container table tbody {
  display: flex;
  flex-direction: column;
}
app-root app-main.mobile data-table-sm .table-container table tbody tr {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  border-bottom: 1px solid var(--radio-border-color);
  padding: 15px 0;
  height: auto !important;
}
app-root app-main.mobile data-table-sm .table-container table tbody tr:last-of-type, app-root app-main.mobile data-table-sm .table-container table tbody tr:nth-last-child(-n+2) {
  border-bottom: 0;
}
app-root app-main.mobile data-table-sm .table-container table tbody tr td {
  border-bottom: 0;
  padding: 0 !important;
  height: auto;
  min-height: 0;
  display: flex;
  align-items: center;
}
app-root app-main.mobile data-table-sm .table-container table tbody tr td.cdk-column-checkbox,
app-root app-main.mobile data-table-sm .table-container table tbody tr td.cdk-column-menu,
app-root app-main.mobile data-table-sm .table-container table tbody tr td.cdk-column-dieta,
app-root app-main.mobile data-table-sm .table-container table tbody tr td.cdk-column-allergenes {
  display: none;
}
app-root app-main.mobile data-table-sm .table-container table tbody tr td.cdk-column-name {
  width: 50%;
}
app-root app-main.mobile data-table-sm .table-container table tbody tr td.cdk-column-price {
  width: 50%;
  justify-content: flex-end;
  font-weight: var(--font-semibold);
}
app-root app-main.mobile data-table-sm .table-container table tbody tr td.cdk-column-price::before {
  position: relative;
  content: "€";
  font-family: var(--default-font);
  font-size: 10px;
  font-weight: var(--font-regular);
  color: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  margin-right: 5px;
}
app-root app-main.mobile data-table-sm .table-container table tbody tr td.cdk-column-order {
  width: 50%;
}
app-root app-main.mobile data-table-sm .table-container table tbody tr td.cdk-column-updateDate {
  width: 50%;
  justify-content: flex-end;
}
app-root app-main.mobile data-table-sm .table-container table tbody tr td.cdk-column-options {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100%;
  opacity: 0;
  z-index: 100;
}
app-root app-main.mobile data-table-sm .grid-container {
  order: 4;
}
app-root app-main.mobile data-table-sm mat-paginator {
  order: 5;
  height: 60px;
  position: fixed;
  bottom: 0;
  z-index: 997;
  width: calc(100% - 40px);
}
app-root app-main.mobile data-table-sm mat-paginator .mat-paginator-container {
  padding: 0;
  justify-content: center;
}
app-root app-main.mobile data-table-sm mat-paginator .mat-paginator-container .mat-paginator-page-size {
  display: none;
}
app-root app-main.mobile data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
app-root app-main.mobile data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-previous {
  position: absolute;
  left: 0;
}
app-root app-main.mobile data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-next {
  position: absolute;
  right: 0;
}
app-root app-main.mobile product-filters {
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  padding: 0;
}
app-root app-main.mobile product-filters .product-filters-container {
  height: auto !important;
  max-height: unset !important;
  flex-direction: column !important;
  width: 100vw !important;
  padding: 0 20px !important;
  align-items: unset !important;
  margin-left: 0 !important;
}
app-root app-main.mobile product-filters .product-filters-container mat-label {
  padding: 0 !important;
  text-align: center !important;
  margin-bottom: 10px;
  margin-top: 20px;
}
app-root app-main.mobile product-filters .product-filters-container .select-container {
  margin-bottom: 10px;
  align-items: center;
}
app-root app-main.mobile product-filters .product-filters-container .select-container mat-label {
  width: 33.333%;
  text-align: left !important;
}
app-root app-main.mobile product-filters .product-filters-container .select-container mat-select {
  flex: 1;
  max-width: unset !important;
  padding: 10px !important;
}
app-root app-main.mobile product-filters .product-filters-container mat-slide-toggle {
  margin-bottom: 10px !important;
}
app-root app-main.mobile product-filters .product-filters-container .action-container {
  margin-bottom: 20px;
}
app-root app-main.mobile .spinner-container {
  width: 100% !important;
}
app-root app-main.mobile app-product-editor {
  padding: 0 !important;
}
app-root app-main.mobile app-product-editor .working-community {
  display: none !important;
}
app-root app-main.mobile .editor-header,
app-root app-main.mobile .asset-editor-header {
  margin-right: 0 !important;
  padding-top: 0 !important;
}
app-root app-main.mobile .editor-header .select-container .mat-select-trigger,
app-root app-main.mobile .asset-editor-header .select-container .mat-select-trigger {
  height: 40px;
}
app-root app-main.mobile .editor-header .action-container,
app-root app-main.mobile .asset-editor-header .action-container {
  padding: 0 !important;
  justify-content: flex-end !important;
}
app-root app-main.mobile .editor-header .action-container app-icon,
app-root app-main.mobile .asset-editor-header .action-container app-icon {
  padding: 0 0 0 20px !important;
  min-width: unset !important;
}
app-root app-main.mobile .editor-header .action-container app-icon:first-of-type,
app-root app-main.mobile .asset-editor-header .action-container app-icon:first-of-type {
  padding-left: 0 !important;
}
app-root app-main.mobile .product-editor-container {
  padding-top: 20px !important;
}
app-root app-main.mobile .product-editor-container .base-product-editor-tab-container {
  display: flex;
  flex-flow: row wrap;
  padding: 0 !important;
}
app-root app-main.mobile base-asset-editor .asset-editor-container {
  width: 100% !important;
  height: auto !important;
  flex-direction: column !important;
  padding: 0 !important;
  margin-top: 20px !important;
}
app-root app-main.mobile .preview-container {
  width: 100% !important;
  height: 250px !important;
  margin-bottom: 20px !important;
  max-width: unset !important;
}
app-root app-main.mobile .form-field-container {
  width: 100% !important;
  padding: 0 !important;
  right: unset !important;
  border: 0 !important;
}
app-root app-main.mobile .form-field-container > * {
  padding: 0 !important;
  margin-bottom: 20px !important;
}
app-root app-main.mobile .form-field-container .row {
  flex-direction: column !important;
  width: 100%;
  margin-top: 0 !important;
}
app-root app-main.mobile .form-field-container .row .form-field:last-of-type {
  margin-bottom: 0 !important;
}
app-root app-main.mobile .form-field-container .file-container {
  width: 100% !important;
  height: auto !important;
  max-width: unset !important;
  margin-bottom: 20px !important;
}
app-root app-main.mobile .form-field-container .file-container .form-file {
  width: 100% !important;
  height: 250px !important;
  max-width: unset !important;
}
app-root app-main.mobile .form-field-container .file-container .form-file .qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
}
app-root app-main.mobile .form-field-container .file-container label {
  margin-bottom: 5px;
}
app-root app-main.mobile .form-field-container .form-fields-container {
  width: 100% !important;
  padding: 0 !important;
}
app-root app-main.mobile .form-field-container .form-field {
  padding: 0 !important;
  width: 100% !important;
  margin-bottom: 20px !important;
}
app-root app-main.mobile .form-field-container .form-field label {
  margin-bottom: 5px;
}
app-root app-main.mobile .form-field-container .form-field label.main {
  margin-bottom: 0 !important;
}
app-root app-main.mobile .form-field-container .form-field input {
  margin-top: 0 !important;
}
app-root app-main.mobile .form-field-container .form-field > app-icon {
  bottom: 0;
  height: 42px !important;
  background-color: var(--form-background);
  border-radius: 10px;
}
app-root app-main.mobile .form-field-container .form-field > .row {
  margin-bottom: 20px;
}
app-root app-main.mobile .form-field-container .chips-container {
  min-height: 150px !important;
}
app-root app-main.mobile .form-field-container .chips-container app-icon {
  height: 30px !important;
}
app-root app-main.mobile .form-field-container app-geocode .geocode-container {
  display: flex !important;
}
app-root app-main.mobile .form-field-container app-geocode .geocode-container > div:first-of-type {
  width: unset !important;
  margin-right: 20px !important;
  flex: 1 !important;
}
app-root app-main.mobile .form-field-container app-geocode .geocode-container > div.actions {
  flex: unset !important;
  width: 40px !important;
}
app-root app-main.mobile .form-field-container app-geocode .geocode-container > div.actions app-icon {
  font-size: 23px !important;
  background-color: var(--transparent-color) !important;
  height: 23px !important;
  width: 23px !important;
  margin-top: 20px;
}
app-root app-main.mobile .base-values-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 0;
}
app-root app-main.mobile .base-values-container .base-image {
  width: 100%;
}
app-root app-main.mobile .base-values-container .base-image file-form-field.file-input {
  width: 100% !important;
  max-width: unset !important;
  height: 500px !important;
  padding: 0 !important;
  margin-bottom: 20px !important;
}
app-root app-main.mobile .base-values-container .form-field-container {
  width: 100% !important;
}
app-root app-main.mobile .base-values-container .form-field-container > * {
  margin-top: 0 !important;
  padding: 0 !important;
  margin-bottom: 20px !important;
}
app-root app-main.mobile .base-values-container .form-field-container datalean-editor ejs-richtexteditor {
  height: 300px !important;
}
app-root app-main.mobile .base-values-container .form-field-container container-field {
  display: flex;
  flex-direction: column;
}
app-root app-main.mobile .base-values-container .form-field-container container-field > label {
  margin-bottom: 10px;
}
app-root app-main.mobile .base-values-container .form-field-container container-field container-card .base-values-container {
  overflow: hidden;
}
app-root app-main.mobile .base-values-container .form-field-container container-field container-card .base-values-container product-field-editor {
  margin-top: 0 !important;
  margin-bottom: 20px !important;
}
app-root app-main.mobile .customizations-container {
  width: 100%;
  float: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-bottom: 20px;
}
app-root app-main.mobile .customizations-container > * {
  padding: 0 !important;
  margin-bottom: 20px;
}
app-root app-main.mobile .categories-container {
  width: 100%;
  float: none;
  display: flex;
  flex-direction: column;
  padding: 0;
}
app-root app-main.mobile drag-and-drop-zone {
  display: none !important;
}
app-root app-main.mobile app-media-library td.cdk-column-name,
app-root app-main.mobile app-media-library td.cdk-column-lastModifiedDate,
app-root app-main.mobile app-media-library td.cdk-column-entityType {
  width: 100% !important;
}
app-root app-main.mobile app-media-library td.cdk-column-fullPath,
app-root app-main.mobile app-media-library td.cdk-column-owner,
app-root app-main.mobile app-media-library td.cdk-column-elementsNumber,
app-root app-main.mobile app-media-library td.cdk-column-status {
  display: none !important;
}
app-root app-main.mobile app-asset-editor .asset-editor-header {
  position: fixed;
  background-color: var(--white-color);
  z-index: 100;
  width: calc(100% - 40px);
}
app-root app-main.mobile app-asset-editor base-asset-editor {
  height: auto;
  overflow-y: auto;
  margin-top: 50px;
}
app-root app-main.mobile app-asset-editor base-asset-editor > .container {
  display: none;
}
app-root app-main.mobile app-communities td.cdk-column-name,
app-root app-main.mobile app-communities td.cdk-column-category {
  width: 100% !important;
}
app-root app-main.mobile app-communities community-editor-tab {
  margin-top: 20px !important;
}
app-root app-main.mobile app-community-editor .mat-tab-body-content {
  overflow: hidden !important;
}
app-root app-main.mobile app-time-range > .row,
app-root app-main.mobile app-time-range-interval > .row {
  margin-bottom: 20px !important;
}
app-root app-main.mobile app-time-range > .row .file-container,
app-root app-main.mobile app-time-range-interval > .row .file-container {
  display: none !important;
}
app-root app-main.mobile app-time-range > .row > .column,
app-root app-main.mobile app-time-range-interval > .row > .column {
  width: 100% !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  margin-bottom: 20px !important;
}
app-root app-main.mobile app-time-range > .row > .column > .row,
app-root app-main.mobile app-time-range-interval > .row > .column > .row {
  flex-direction: row !important;
  justify-content: space-between !important;
}
app-root app-main.mobile app-time-range > .row > .column > .row > .column,
app-root app-main.mobile app-time-range-interval > .row > .column > .row > .column {
  padding: 0 !important;
  width: calc(50% - 10px) !important;
}
app-root app-main.mobile app-draggable-tree {
  padding: 0 !important;
}
app-root app-main.mobile app-draggable-tree .tree-container .tree-header {
  justify-content: flex-end !important;
  margin-bottom: 20px;
}
app-root app-main.mobile app-draggable-tree .tree-container .tree-header > app-icon {
  margin-left: 10px;
}
app-root app-main.mobile app-draggable-tree .tree-container mat-tree mat-tree-node {
  padding: 10px 0;
}
app-root app-main.mobile app-draggable-tree .tree-container mat-tree mat-tree-node mat-checkbox {
  display: none !important;
}
app-root app-main.mobile app-draggable-tree .tree-container mat-tree mat-tree-node app-icon.add,
app-root app-main.mobile app-draggable-tree .tree-container mat-tree mat-tree-node app-icon.remove {
  font-size: 16px !important;
  margin-left: 20px;
}
app-root app-main.mobile customization-group-draggable-tree > .main-container {
  height: auto !important;
}

@media only screen and (max-width: 540px) {
  app-modal {
    width: 100%;
    height: 100%;
    border-radius: 0 !important;
    border-bottom: 0 !important;
  }
  app-modal div.select-file-for-import-container {
    width: calc(100% - 20px) !important;
  }
  app-modal modal-header {
    width: 100% !important;
  }
  app-modal modal-header app-icon {
    padding: 5px 0 5px 30px !important;
  }
  app-modal form {
    width: 100% !important;
  }
  app-modal form .file-container2 div {
    width: 100% !important;
  }
  app-modal form .select-container mat-select {
    width: 100% !important;
    height: 40px !important;
  }
  app-modal form .email-container input {
    width: 100% !important;
  }
  app-modal div.media-library-container,
app-modal div.user-selection-container,
app-modal div.category-selection-container,
app-modal div.folder-creation-container,
app-modal div.simple-dialog-container,
app-modal div.message-container {
    width: 100% !important;
    min-width: 0 !important;
    display: flex !important;
    flex-direction: column !important;
    min-height: 100vh !important;
    padding: 0 !important;
  }
  app-modal div.media-library-container modal-header,
app-modal div.user-selection-container modal-header,
app-modal div.category-selection-container modal-header,
app-modal div.folder-creation-container modal-header,
app-modal div.simple-dialog-container modal-header,
app-modal div.message-container modal-header {
    padding: 20px;
    background: var(--white-color);
    z-index: 10;
    height: 64px;
    box-sizing: border-box;
  }
  app-modal div.media-library-container .table-container,
app-modal div.media-library-container .main-container,
app-modal div.user-selection-container .table-container,
app-modal div.user-selection-container .main-container,
app-modal div.category-selection-container .table-container,
app-modal div.category-selection-container .main-container,
app-modal div.folder-creation-container .table-container,
app-modal div.folder-creation-container .main-container,
app-modal div.simple-dialog-container .table-container,
app-modal div.simple-dialog-container .main-container,
app-modal div.message-container .table-container,
app-modal div.message-container .main-container {
    padding: 0px 20px 0 20px !important;
    overflow-y: auto;
    flex-direction: column;
    max-height: unset;
    height: 100%;
  }
  app-modal div.media-library-container .table-container .asset-editor-container,
app-modal div.media-library-container .main-container .asset-editor-container,
app-modal div.user-selection-container .table-container .asset-editor-container,
app-modal div.user-selection-container .main-container .asset-editor-container,
app-modal div.category-selection-container .table-container .asset-editor-container,
app-modal div.category-selection-container .main-container .asset-editor-container,
app-modal div.folder-creation-container .table-container .asset-editor-container,
app-modal div.folder-creation-container .main-container .asset-editor-container,
app-modal div.simple-dialog-container .table-container .asset-editor-container,
app-modal div.simple-dialog-container .main-container .asset-editor-container,
app-modal div.message-container .table-container .asset-editor-container,
app-modal div.message-container .main-container .asset-editor-container {
    padding-top: 0 !important;
    padding-right: 0 !important;
    margin-bottom: 20px;
    margin-top: 20px;
    flex-direction: column;
  }
  app-modal div.media-library-container .table-container .asset-editor-container .preview-container,
app-modal div.media-library-container .main-container .asset-editor-container .preview-container,
app-modal div.user-selection-container .table-container .asset-editor-container .preview-container,
app-modal div.user-selection-container .main-container .asset-editor-container .preview-container,
app-modal div.category-selection-container .table-container .asset-editor-container .preview-container,
app-modal div.category-selection-container .main-container .asset-editor-container .preview-container,
app-modal div.folder-creation-container .table-container .asset-editor-container .preview-container,
app-modal div.folder-creation-container .main-container .asset-editor-container .preview-container,
app-modal div.simple-dialog-container .table-container .asset-editor-container .preview-container,
app-modal div.simple-dialog-container .main-container .asset-editor-container .preview-container,
app-modal div.message-container .table-container .asset-editor-container .preview-container,
app-modal div.message-container .main-container .asset-editor-container .preview-container {
    width: 100% !important;
    max-width: unset !important;
    height: 200px !important;
    margin-bottom: 20px !important;
  }
  app-modal div.media-library-container .table-container .asset-editor-container .form-field-container .form-field,
app-modal div.media-library-container .main-container .asset-editor-container .form-field-container .form-field,
app-modal div.user-selection-container .table-container .asset-editor-container .form-field-container .form-field,
app-modal div.user-selection-container .main-container .asset-editor-container .form-field-container .form-field,
app-modal div.category-selection-container .table-container .asset-editor-container .form-field-container .form-field,
app-modal div.category-selection-container .main-container .asset-editor-container .form-field-container .form-field,
app-modal div.folder-creation-container .table-container .asset-editor-container .form-field-container .form-field,
app-modal div.folder-creation-container .main-container .asset-editor-container .form-field-container .form-field,
app-modal div.simple-dialog-container .table-container .asset-editor-container .form-field-container .form-field,
app-modal div.simple-dialog-container .main-container .asset-editor-container .form-field-container .form-field,
app-modal div.message-container .table-container .asset-editor-container .form-field-container .form-field,
app-modal div.message-container .main-container .asset-editor-container .form-field-container .form-field {
    padding: 0 !important;
    margin-bottom: 20px;
  }
  app-modal div.media-library-container .table-container .asset-editor-container .form-field-container .form-field > app-icon,
app-modal div.media-library-container .main-container .asset-editor-container .form-field-container .form-field > app-icon,
app-modal div.user-selection-container .table-container .asset-editor-container .form-field-container .form-field > app-icon,
app-modal div.user-selection-container .main-container .asset-editor-container .form-field-container .form-field > app-icon,
app-modal div.category-selection-container .table-container .asset-editor-container .form-field-container .form-field > app-icon,
app-modal div.category-selection-container .main-container .asset-editor-container .form-field-container .form-field > app-icon,
app-modal div.folder-creation-container .table-container .asset-editor-container .form-field-container .form-field > app-icon,
app-modal div.folder-creation-container .main-container .asset-editor-container .form-field-container .form-field > app-icon,
app-modal div.simple-dialog-container .table-container .asset-editor-container .form-field-container .form-field > app-icon,
app-modal div.simple-dialog-container .main-container .asset-editor-container .form-field-container .form-field > app-icon,
app-modal div.message-container .table-container .asset-editor-container .form-field-container .form-field > app-icon,
app-modal div.message-container .main-container .asset-editor-container .form-field-container .form-field > app-icon {
    bottom: 0;
    height: 42px !important;
  }
  app-modal div.media-library-container .table-container .path,
app-modal div.media-library-container .table-container .container,
app-modal div.media-library-container .main-container .path,
app-modal div.media-library-container .main-container .container,
app-modal div.user-selection-container .table-container .path,
app-modal div.user-selection-container .table-container .container,
app-modal div.user-selection-container .main-container .path,
app-modal div.user-selection-container .main-container .container,
app-modal div.category-selection-container .table-container .path,
app-modal div.category-selection-container .table-container .container,
app-modal div.category-selection-container .main-container .path,
app-modal div.category-selection-container .main-container .container,
app-modal div.folder-creation-container .table-container .path,
app-modal div.folder-creation-container .table-container .container,
app-modal div.folder-creation-container .main-container .path,
app-modal div.folder-creation-container .main-container .container,
app-modal div.simple-dialog-container .table-container .path,
app-modal div.simple-dialog-container .table-container .container,
app-modal div.simple-dialog-container .main-container .path,
app-modal div.simple-dialog-container .main-container .container,
app-modal div.message-container .table-container .path,
app-modal div.message-container .table-container .container,
app-modal div.message-container .main-container .path,
app-modal div.message-container .main-container .container {
    display: none !important;
  }
  app-modal div.media-library-container .table-container > .chips-container,
app-modal div.media-library-container .main-container > .chips-container,
app-modal div.user-selection-container .table-container > .chips-container,
app-modal div.user-selection-container .main-container > .chips-container,
app-modal div.category-selection-container .table-container > .chips-container,
app-modal div.category-selection-container .main-container > .chips-container,
app-modal div.folder-creation-container .table-container > .chips-container,
app-modal div.folder-creation-container .main-container > .chips-container,
app-modal div.simple-dialog-container .table-container > .chips-container,
app-modal div.simple-dialog-container .main-container > .chips-container,
app-modal div.message-container .table-container > .chips-container,
app-modal div.message-container .main-container > .chips-container {
    position: relative !important;
    left: unset !important;
    height: 250px !important;
    width: 100% !important;
    margin-bottom: 20px;
    top: unset !important;
    flex: unset !important;
  }
  app-modal div.media-library-container .table-container > .table-container,
app-modal div.media-library-container .table-container > .tree-container,
app-modal div.media-library-container .main-container > .table-container,
app-modal div.media-library-container .main-container > .tree-container,
app-modal div.user-selection-container .table-container > .table-container,
app-modal div.user-selection-container .table-container > .tree-container,
app-modal div.user-selection-container .main-container > .table-container,
app-modal div.user-selection-container .main-container > .tree-container,
app-modal div.category-selection-container .table-container > .table-container,
app-modal div.category-selection-container .table-container > .tree-container,
app-modal div.category-selection-container .main-container > .table-container,
app-modal div.category-selection-container .main-container > .tree-container,
app-modal div.folder-creation-container .table-container > .table-container,
app-modal div.folder-creation-container .table-container > .tree-container,
app-modal div.folder-creation-container .main-container > .table-container,
app-modal div.folder-creation-container .main-container > .tree-container,
app-modal div.simple-dialog-container .table-container > .table-container,
app-modal div.simple-dialog-container .table-container > .tree-container,
app-modal div.simple-dialog-container .main-container > .table-container,
app-modal div.simple-dialog-container .main-container > .tree-container,
app-modal div.message-container .table-container > .table-container,
app-modal div.message-container .table-container > .tree-container,
app-modal div.message-container .main-container > .table-container,
app-modal div.message-container .main-container > .tree-container {
    position: relative !important;
    right: unset !important;
    height: auto !important;
    width: 100% !important;
    flex: unset !important;
  }
  app-modal div.media-library-container .table-container mat-tab-group .mat-tab-label.mat-tab-label-active,
app-modal div.media-library-container .main-container mat-tab-group .mat-tab-label.mat-tab-label-active,
app-modal div.user-selection-container .table-container mat-tab-group .mat-tab-label.mat-tab-label-active,
app-modal div.user-selection-container .main-container mat-tab-group .mat-tab-label.mat-tab-label-active,
app-modal div.category-selection-container .table-container mat-tab-group .mat-tab-label.mat-tab-label-active,
app-modal div.category-selection-container .main-container mat-tab-group .mat-tab-label.mat-tab-label-active,
app-modal div.folder-creation-container .table-container mat-tab-group .mat-tab-label.mat-tab-label-active,
app-modal div.folder-creation-container .main-container mat-tab-group .mat-tab-label.mat-tab-label-active,
app-modal div.simple-dialog-container .table-container mat-tab-group .mat-tab-label.mat-tab-label-active,
app-modal div.simple-dialog-container .main-container mat-tab-group .mat-tab-label.mat-tab-label-active,
app-modal div.message-container .table-container mat-tab-group .mat-tab-label.mat-tab-label-active,
app-modal div.message-container .main-container mat-tab-group .mat-tab-label.mat-tab-label-active {
    color: var(--primary-color) !important;
  }
  app-modal div.media-library-container .table-container mat-tab-group .mat-tab-label .mat-tab-label-content,
app-modal div.media-library-container .main-container mat-tab-group .mat-tab-label .mat-tab-label-content,
app-modal div.user-selection-container .table-container mat-tab-group .mat-tab-label .mat-tab-label-content,
app-modal div.user-selection-container .main-container mat-tab-group .mat-tab-label .mat-tab-label-content,
app-modal div.category-selection-container .table-container mat-tab-group .mat-tab-label .mat-tab-label-content,
app-modal div.category-selection-container .main-container mat-tab-group .mat-tab-label .mat-tab-label-content,
app-modal div.folder-creation-container .table-container mat-tab-group .mat-tab-label .mat-tab-label-content,
app-modal div.folder-creation-container .main-container mat-tab-group .mat-tab-label .mat-tab-label-content,
app-modal div.simple-dialog-container .table-container mat-tab-group .mat-tab-label .mat-tab-label-content,
app-modal div.simple-dialog-container .main-container mat-tab-group .mat-tab-label .mat-tab-label-content,
app-modal div.message-container .table-container mat-tab-group .mat-tab-label .mat-tab-label-content,
app-modal div.message-container .main-container mat-tab-group .mat-tab-label .mat-tab-label-content {
    text-transform: uppercase !important;
  }
  app-modal div.media-library-container .table-container data-table-sm-header .table-header-container,
app-modal div.media-library-container .main-container data-table-sm-header .table-header-container,
app-modal div.user-selection-container .table-container data-table-sm-header .table-header-container,
app-modal div.user-selection-container .main-container data-table-sm-header .table-header-container,
app-modal div.category-selection-container .table-container data-table-sm-header .table-header-container,
app-modal div.category-selection-container .main-container data-table-sm-header .table-header-container,
app-modal div.folder-creation-container .table-container data-table-sm-header .table-header-container,
app-modal div.folder-creation-container .main-container data-table-sm-header .table-header-container,
app-modal div.simple-dialog-container .table-container data-table-sm-header .table-header-container,
app-modal div.simple-dialog-container .main-container data-table-sm-header .table-header-container,
app-modal div.message-container .table-container data-table-sm-header .table-header-container,
app-modal div.message-container .main-container data-table-sm-header .table-header-container {
    padding: 10px 0 !important;
  }
  app-modal div.media-library-container .table-container data-table-sm-header .table-header-container .optional-controls-container,
app-modal div.media-library-container .main-container data-table-sm-header .table-header-container .optional-controls-container,
app-modal div.user-selection-container .table-container data-table-sm-header .table-header-container .optional-controls-container,
app-modal div.user-selection-container .main-container data-table-sm-header .table-header-container .optional-controls-container,
app-modal div.category-selection-container .table-container data-table-sm-header .table-header-container .optional-controls-container,
app-modal div.category-selection-container .main-container data-table-sm-header .table-header-container .optional-controls-container,
app-modal div.folder-creation-container .table-container data-table-sm-header .table-header-container .optional-controls-container,
app-modal div.folder-creation-container .main-container data-table-sm-header .table-header-container .optional-controls-container,
app-modal div.simple-dialog-container .table-container data-table-sm-header .table-header-container .optional-controls-container,
app-modal div.simple-dialog-container .main-container data-table-sm-header .table-header-container .optional-controls-container,
app-modal div.message-container .table-container data-table-sm-header .table-header-container .optional-controls-container,
app-modal div.message-container .main-container data-table-sm-header .table-header-container .optional-controls-container {
    justify-content: flex-end;
  }
  app-modal div.media-library-container .table-container data-table-sm-header .table-header-container .optional-controls-container search-field,
app-modal div.media-library-container .main-container data-table-sm-header .table-header-container .optional-controls-container search-field,
app-modal div.user-selection-container .table-container data-table-sm-header .table-header-container .optional-controls-container search-field,
app-modal div.user-selection-container .main-container data-table-sm-header .table-header-container .optional-controls-container search-field,
app-modal div.category-selection-container .table-container data-table-sm-header .table-header-container .optional-controls-container search-field,
app-modal div.category-selection-container .main-container data-table-sm-header .table-header-container .optional-controls-container search-field,
app-modal div.folder-creation-container .table-container data-table-sm-header .table-header-container .optional-controls-container search-field,
app-modal div.folder-creation-container .main-container data-table-sm-header .table-header-container .optional-controls-container search-field,
app-modal div.simple-dialog-container .table-container data-table-sm-header .table-header-container .optional-controls-container search-field,
app-modal div.simple-dialog-container .main-container data-table-sm-header .table-header-container .optional-controls-container search-field,
app-modal div.message-container .table-container data-table-sm-header .table-header-container .optional-controls-container search-field,
app-modal div.message-container .main-container data-table-sm-header .table-header-container .optional-controls-container search-field {
    justify-content: flex-end;
  }
  app-modal div.media-library-container .table-container data-table-sm .breadcrumb,
app-modal div.media-library-container .main-container data-table-sm .breadcrumb,
app-modal div.user-selection-container .table-container data-table-sm .breadcrumb,
app-modal div.user-selection-container .main-container data-table-sm .breadcrumb,
app-modal div.category-selection-container .table-container data-table-sm .breadcrumb,
app-modal div.category-selection-container .main-container data-table-sm .breadcrumb,
app-modal div.folder-creation-container .table-container data-table-sm .breadcrumb,
app-modal div.folder-creation-container .main-container data-table-sm .breadcrumb,
app-modal div.simple-dialog-container .table-container data-table-sm .breadcrumb,
app-modal div.simple-dialog-container .main-container data-table-sm .breadcrumb,
app-modal div.message-container .table-container data-table-sm .breadcrumb,
app-modal div.message-container .main-container data-table-sm .breadcrumb {
    margin: 0 !important;
    border-bottom: 1px solid var(--light-gray);
  }
  app-modal div.media-library-container .table-container data-table-sm mat-grid-list > div,
app-modal div.media-library-container .main-container data-table-sm mat-grid-list > div,
app-modal div.user-selection-container .table-container data-table-sm mat-grid-list > div,
app-modal div.user-selection-container .main-container data-table-sm mat-grid-list > div,
app-modal div.category-selection-container .table-container data-table-sm mat-grid-list > div,
app-modal div.category-selection-container .main-container data-table-sm mat-grid-list > div,
app-modal div.folder-creation-container .table-container data-table-sm mat-grid-list > div,
app-modal div.folder-creation-container .main-container data-table-sm mat-grid-list > div,
app-modal div.simple-dialog-container .table-container data-table-sm mat-grid-list > div,
app-modal div.simple-dialog-container .main-container data-table-sm mat-grid-list > div,
app-modal div.message-container .table-container data-table-sm mat-grid-list > div,
app-modal div.message-container .main-container data-table-sm mat-grid-list > div {
    display: flex;
    flex-flow: row wrap;
  }
  app-modal div.media-library-container .table-container data-table-sm mat-grid-list > div mat-grid-tile,
app-modal div.media-library-container .main-container data-table-sm mat-grid-list > div mat-grid-tile,
app-modal div.user-selection-container .table-container data-table-sm mat-grid-list > div mat-grid-tile,
app-modal div.user-selection-container .main-container data-table-sm mat-grid-list > div mat-grid-tile,
app-modal div.category-selection-container .table-container data-table-sm mat-grid-list > div mat-grid-tile,
app-modal div.category-selection-container .main-container data-table-sm mat-grid-list > div mat-grid-tile,
app-modal div.folder-creation-container .table-container data-table-sm mat-grid-list > div mat-grid-tile,
app-modal div.folder-creation-container .main-container data-table-sm mat-grid-list > div mat-grid-tile,
app-modal div.simple-dialog-container .table-container data-table-sm mat-grid-list > div mat-grid-tile,
app-modal div.simple-dialog-container .main-container data-table-sm mat-grid-list > div mat-grid-tile,
app-modal div.message-container .table-container data-table-sm mat-grid-list > div mat-grid-tile,
app-modal div.message-container .main-container data-table-sm mat-grid-list > div mat-grid-tile {
    position: relative !important;
    width: calc(50% - 10px) !important;
    margin-right: 20px !important;
    margin-top: 20px !important;
    left: unset !important;
    top: unset !important;
  }
  app-modal div.media-library-container .table-container data-table-sm mat-grid-list > div mat-grid-tile:nth-of-type(2n),
app-modal div.media-library-container .main-container data-table-sm mat-grid-list > div mat-grid-tile:nth-of-type(2n),
app-modal div.user-selection-container .table-container data-table-sm mat-grid-list > div mat-grid-tile:nth-of-type(2n),
app-modal div.user-selection-container .main-container data-table-sm mat-grid-list > div mat-grid-tile:nth-of-type(2n),
app-modal div.category-selection-container .table-container data-table-sm mat-grid-list > div mat-grid-tile:nth-of-type(2n),
app-modal div.category-selection-container .main-container data-table-sm mat-grid-list > div mat-grid-tile:nth-of-type(2n),
app-modal div.folder-creation-container .table-container data-table-sm mat-grid-list > div mat-grid-tile:nth-of-type(2n),
app-modal div.folder-creation-container .main-container data-table-sm mat-grid-list > div mat-grid-tile:nth-of-type(2n),
app-modal div.simple-dialog-container .table-container data-table-sm mat-grid-list > div mat-grid-tile:nth-of-type(2n),
app-modal div.simple-dialog-container .main-container data-table-sm mat-grid-list > div mat-grid-tile:nth-of-type(2n),
app-modal div.message-container .table-container data-table-sm mat-grid-list > div mat-grid-tile:nth-of-type(2n),
app-modal div.message-container .main-container data-table-sm mat-grid-list > div mat-grid-tile:nth-of-type(2n) {
    margin-right: 0 !important;
  }
  app-modal div.media-library-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card,
app-modal div.media-library-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card,
app-modal div.user-selection-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card,
app-modal div.user-selection-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card,
app-modal div.category-selection-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card,
app-modal div.category-selection-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card,
app-modal div.folder-creation-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card,
app-modal div.folder-creation-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card,
app-modal div.simple-dialog-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card,
app-modal div.simple-dialog-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card,
app-modal div.message-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card,
app-modal div.message-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card {
    padding: 0 !important;
  }
  app-modal div.media-library-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header,
app-modal div.media-library-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header,
app-modal div.user-selection-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header,
app-modal div.user-selection-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header,
app-modal div.category-selection-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header,
app-modal div.category-selection-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header,
app-modal div.folder-creation-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header,
app-modal div.folder-creation-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header,
app-modal div.simple-dialog-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header,
app-modal div.simple-dialog-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header,
app-modal div.message-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header,
app-modal div.message-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header {
    margin-top: 5px !important;
  }
  app-modal div.media-library-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text,
app-modal div.media-library-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text,
app-modal div.user-selection-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text,
app-modal div.user-selection-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text,
app-modal div.category-selection-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text,
app-modal div.category-selection-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text,
app-modal div.folder-creation-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text,
app-modal div.folder-creation-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text,
app-modal div.simple-dialog-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text,
app-modal div.simple-dialog-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text,
app-modal div.message-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text,
app-modal div.message-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text {
    margin: 0 !important;
  }
  app-modal div.media-library-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text mat-card-title,
app-modal div.media-library-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text mat-card-title,
app-modal div.user-selection-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text mat-card-title,
app-modal div.user-selection-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text mat-card-title,
app-modal div.category-selection-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text mat-card-title,
app-modal div.category-selection-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text mat-card-title,
app-modal div.folder-creation-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text mat-card-title,
app-modal div.folder-creation-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text mat-card-title,
app-modal div.simple-dialog-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text mat-card-title,
app-modal div.simple-dialog-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text mat-card-title,
app-modal div.message-container .table-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text mat-card-title,
app-modal div.message-container .main-container data-table-sm mat-grid-list > div mat-grid-tile mat-card mat-card-header .mat-card-header-text mat-card-title {
    font-size: 12px !important;
  }
  app-modal div.media-library-container .table-container data-table-sm mat-paginator,
app-modal div.media-library-container .main-container data-table-sm mat-paginator,
app-modal div.user-selection-container .table-container data-table-sm mat-paginator,
app-modal div.user-selection-container .main-container data-table-sm mat-paginator,
app-modal div.category-selection-container .table-container data-table-sm mat-paginator,
app-modal div.category-selection-container .main-container data-table-sm mat-paginator,
app-modal div.folder-creation-container .table-container data-table-sm mat-paginator,
app-modal div.folder-creation-container .main-container data-table-sm mat-paginator,
app-modal div.simple-dialog-container .table-container data-table-sm mat-paginator,
app-modal div.simple-dialog-container .main-container data-table-sm mat-paginator,
app-modal div.message-container .table-container data-table-sm mat-paginator,
app-modal div.message-container .main-container data-table-sm mat-paginator {
    padding: 0 10px;
  }
  app-modal div.media-library-container .table-container data-table-sm mat-paginator .mat-paginator-container,
app-modal div.media-library-container .main-container data-table-sm mat-paginator .mat-paginator-container,
app-modal div.user-selection-container .table-container data-table-sm mat-paginator .mat-paginator-container,
app-modal div.user-selection-container .main-container data-table-sm mat-paginator .mat-paginator-container,
app-modal div.category-selection-container .table-container data-table-sm mat-paginator .mat-paginator-container,
app-modal div.category-selection-container .main-container data-table-sm mat-paginator .mat-paginator-container,
app-modal div.folder-creation-container .table-container data-table-sm mat-paginator .mat-paginator-container,
app-modal div.folder-creation-container .main-container data-table-sm mat-paginator .mat-paginator-container,
app-modal div.simple-dialog-container .table-container data-table-sm mat-paginator .mat-paginator-container,
app-modal div.simple-dialog-container .main-container data-table-sm mat-paginator .mat-paginator-container,
app-modal div.message-container .table-container data-table-sm mat-paginator .mat-paginator-container,
app-modal div.message-container .main-container data-table-sm mat-paginator .mat-paginator-container {
    padding: 0;
    justify-content: center;
  }
  app-modal div.media-library-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-page-size,
app-modal div.media-library-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-page-size,
app-modal div.user-selection-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-page-size,
app-modal div.user-selection-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-page-size,
app-modal div.category-selection-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-page-size,
app-modal div.category-selection-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-page-size,
app-modal div.folder-creation-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-page-size,
app-modal div.folder-creation-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-page-size,
app-modal div.simple-dialog-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-page-size,
app-modal div.simple-dialog-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-page-size,
app-modal div.message-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-page-size,
app-modal div.message-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-page-size {
    display: none;
  }
  app-modal div.media-library-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions,
app-modal div.media-library-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions,
app-modal div.user-selection-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions,
app-modal div.user-selection-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions,
app-modal div.category-selection-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions,
app-modal div.category-selection-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions,
app-modal div.folder-creation-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions,
app-modal div.folder-creation-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions,
app-modal div.simple-dialog-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions,
app-modal div.simple-dialog-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions,
app-modal div.message-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions,
app-modal div.message-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  app-modal div.media-library-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-previous,
app-modal div.media-library-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-previous,
app-modal div.user-selection-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-previous,
app-modal div.user-selection-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-previous,
app-modal div.category-selection-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-previous,
app-modal div.category-selection-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-previous,
app-modal div.folder-creation-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-previous,
app-modal div.folder-creation-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-previous,
app-modal div.simple-dialog-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-previous,
app-modal div.simple-dialog-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-previous,
app-modal div.message-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-previous,
app-modal div.message-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-previous {
    position: absolute;
    left: 0;
  }
  app-modal div.media-library-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-next,
app-modal div.media-library-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-next,
app-modal div.user-selection-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-next,
app-modal div.user-selection-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-next,
app-modal div.category-selection-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-next,
app-modal div.category-selection-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-next,
app-modal div.folder-creation-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-next,
app-modal div.folder-creation-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-next,
app-modal div.simple-dialog-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-next,
app-modal div.simple-dialog-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-next,
app-modal div.message-container .table-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-next,
app-modal div.message-container .main-container data-table-sm mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-navigation-next {
    position: absolute;
    right: 0;
  }
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #002b7a;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff4081;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #002b7a;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ff4081;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #002b7a;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #ff4081;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #002b7a;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #ff4081;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #002b7a;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ff4081;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #002b7a;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #ff4081;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #002b7a;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ff4081;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #002b7a;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ff4081;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #002b7a;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ff4081;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(0, 43, 122, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 43, 122, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 43, 122, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #002b7a;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 43, 122, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 43, 122, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 43, 122, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 64, 129, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 64, 129, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ff4081;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 64, 129, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 64, 129, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 64, 129, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #002b7a;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #ff4081;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #002b7a;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: #ff4081;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #002b7a;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ff4081;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #002b7a;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ff4081;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #002b7a;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ff4081;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #002b7a;
}
.mat-icon.mat-accent {
  color: #ff4081;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #002b7a;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #ff4081;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #bcc6da;
}

.mat-progress-bar-buffer {
  background-color: #bcc6da;
}

.mat-progress-bar-fill::after {
  background-color: #002b7a;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbccdc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbccdc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ff4081;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #002b7a;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ff4081;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #002b7a;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #002b7a;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff4081;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ff4081;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #002b7a;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ff4081;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 64, 129, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ff4081;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #002b7a;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 43, 122, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #002b7a;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #002b7a;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 43, 122, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ff4081;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 64, 129, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #002b7a;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ff4081;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 191, 215, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #002b7a;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ff4081;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 191, 215, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #002b7a;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 128, 171, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ff4081;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #002b7a;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #ff4081;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #ff4081;
}

/* You can add global styles to this file, and also import other style files */
* {
  font-family: var(--default-font);
  font-weight: var(--font-regular);
}

html,
body {
  height: 100%;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

app-root {
  width: 100%;
}

:focus {
  outline: unset;
}

:root {
  /* nuovi */
  --default-font: "Segma", sans-serif;
  --datalean-icon-font: "DataLean Font Icon";
  --smartCo-icon-font: "Smart Community";
  --font-thin: 100;
  --font-extralight: 200;
  --font-light: 300;
  --font-regular: 400;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  --font-black: 900;
  --white-color: #FFFFFF;
  --black-color: #000000;
  --black-color-05: #00000080;
  --transparent-color: #FFFFFF00;
  --error-color: #FF0000;
  --brand-color-01: #0048CD;
  --brand-color-02: #82BDFF;
  --brand-color-04: #38424C;
  --brand-color-05: #DB202F;
  --brand-color-06: #AB00EB;
  --brand-color-07: #70009A;
  --brand-color-08: #010F34;
  --brand-color-09: #4A4A4A;
  --brand-color-10: #2B82FF;
  --brand-color-11: #002B7A;
  --gray-color-01: #A4A4A4;
  --gray-color-02: #ADADAD;
  --gray-color-03: #E6E6E6;
  --gray-color-04: #CCCCCC;
  --gray-color-05: #D8D8D8;
  --gray-color-06: #DDDDDD;
  --gray-color-07: #F5F5F5;
  --gray-color-08: #FAFAFA;
  --gray-color-09: #979797;
  --gray-color-10: #757575;
  --gray-color-11: #777777;
  --gray-color-12: #F1F1F1;
  --gray-color-13: #9B9B9B;
  --logo-datalean: url("./assets/images/logo-datalean.png");
  --standard-transition: all 0.3s ease-out;
  /* precedenti */
  --button-blue-color: var(--standard-button-color);
  --red-color: #ff3d00;
  --lighter-blue: #82bdff;
  --row-separator-color: rgba(0, 0, 0, 0.12);
  --lighter-gray: rgba(232, 232, 232, 0.3);
  --light-gray: #E6E6E6;
  --medium-gray: #757575;
  --medium-dark-grey: #4A494A;
  --green-color: #42bd41;
  --orange-color: #FF9A00;
  --purple-light-color: #B000F2;
  --purple-color: #70009A;
  --primary-opacity: #337BFF;
  /* COMMUNICATIONS */
  --communication-details-date-color: var(--standard-button-color);
  --communication-details-title-color: var(--primary-toggle-color);
  --communication-details-text-color: var(--form-text);
  --communication-details-attachment-title-color: var(--form-text);
  --communication-details-attachment-detail-color: var(--medium-gray);
  --communication-details-confirmation-background-color: var(--light-gray);
  --communication-details-confirmation-text-color: var(--form-text);
  --communication-details-attachment-icon-color: var(--primary-color);
  --communication-preview-background: rgba(58, 62, 70, 1);
  --communication-preview-button-background: var(--medium-gray);
  --communication-preview-qrcode-image: url("./assets/images/qrcode.png");
  --disabled-label: var(--light-gray);
  --disabled-background: rgba(245, 245, 245, 1);
  --login-background-error: rgb(95, 27, 87);
  /* MAT-NAV-LIST */
  --transparent-background: var(--transparent-color);
  --nav-menu-width: 120px;
  --nav-menu-background-width: 45px;
  --nav-menu-background-width-expanded: 175px;
  --form-label-color: rgba(119, 119, 119, 1);
  --form-checkbox-color: rgba(74, 73, 74, 1);
  --folder-image-local-url: url("./assets/images/media-library/folder.png");
  --file-placeholder-image-local-url: url("./assets/images/media-library/file.png");
  --icon-editor-html-snippet: url("./assets/images/icn-snippet.svg");
  --icon-editor-html-snippet-hover: url("./assets/images/icn-snippet-hover.svg");
  --icon-editor-html-colorfill: url("./assets/images/icn-colorfill.svg");
  --icon-editor-html-colorfill-hover: url("./assets/images/icn-colorfill-hover.svg");
  --icon-editor-html-source: url("./assets/images/icn-source.svg");
  --icon-editor-html-source-hover: url("./assets/images/icn-source-hover.svg");
}

body {
  background-color: var(--background-color);
  overflow: hidden;
  margin: 0;
}
body input {
  outline: none;
}
body table.cdk-table,
body .mat-paginator {
  background-color: var(--background-color);
}
body a {
  text-decoration: none;
}
body .cdk-drag-preview.hotspot {
  width: 30px;
  height: 30px;
  background: var(--primary-color);
  border-radius: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  color: var(--white-color);
  cursor: pointer;
}
body > container-field.cdk-drag-preview,
body > container-field.cdk-drag-dragging,
body > product-field-editor.cdk-drag-preview,
body > product-field-editor.cdk-drag-dragging {
  opacity: 0.5 !important;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
body container-field.cdk-drag-preview .container-with-command-list command-list,
body container-field.cdk-drag-dragging .container-with-command-list command-list,
body product-field-editor.cdk-drag-preview .container-with-command-list command-list,
body product-field-editor.cdk-drag-dragging .container-with-command-list command-list {
  display: none;
}

.mat-calendar-body-selected {
  background-color: var(--button-blue-color) !important;
}

.mat-body,
.mat-body-1,
.mat-typography,
.mat-optgroup-label {
  font: 300 12px/20px var(--default-font);
  letter-spacing: 0.5px;
}

@media (hover: none) and (pointer: coarse) {
  .cdk-overlay-container .mat-tooltip {
    display: none !important;
  }
}
.cdk-overlay-container {
  display: flex;
}
.cdk-overlay-container .cdk-overlay-pane {
  width: auto !important;
}
.cdk-overlay-container .cdk-overlay-pane .mat-autocomplete-panel-above,
.cdk-overlay-container .cdk-overlay-pane .mat-autocomplete-panel {
  border-radius: 10px !important;
  background: var(--mat-option-background-color);
}
.cdk-overlay-container .cdk-overlay-pane .mat-autocomplete-panel-above::-webkit-scrollbar,
.cdk-overlay-container .cdk-overlay-pane .mat-autocomplete-panel::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.cdk-overlay-container .cdk-overlay-pane .mat-autocomplete-panel-above::-webkit-scrollbar-thumb,
.cdk-overlay-container .cdk-overlay-pane .mat-autocomplete-panel::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: var(--light-gray);
  box-shadow: none;
  border: 2px solid var(--white-color);
}
.cdk-overlay-container .cdk-overlay-pane .mat-autocomplete-panel-above::-webkit-scrollbar-track,
.cdk-overlay-container .cdk-overlay-pane .mat-autocomplete-panel::-webkit-scrollbar-track {
  background-color: var(--white-color);
  border-radius: 10px;
  background: var(--white-color);
}
.cdk-overlay-container .cdk-overlay-pane .mat-autocomplete-panel-above .mat-selected .mat-option-text,
.cdk-overlay-container .cdk-overlay-pane .mat-autocomplete-panel .mat-selected .mat-option-text {
  font-family: var(--default-font);
  font-weight: var(--font-regular);
}
.cdk-overlay-container .cdk-overlay-pane .mat-autocomplete-panel-above .mat-option-text,
.cdk-overlay-container .cdk-overlay-pane .mat-autocomplete-panel .mat-option-text {
  font-family: var(--default-font);
  font-size: 14px;
  font-weight: var(--font-regular);
  color: var(--mat-option-text-color);
  line-height: 16px;
  letter-spacing: inherit;
  font-style: normal;
  text-align: left;
  user-select: none;
}
.cdk-overlay-container .cdk-overlay-pane .mat-autocomplete-panel-above mat-option.indent-left,
.cdk-overlay-container .cdk-overlay-pane .mat-autocomplete-panel mat-option.indent-left {
  padding-left: 32px;
}
.cdk-overlay-container .cdk-overlay-pane.comparator-modal app-modal {
  border-radius: 0;
  border-bottom: 0;
}
.cdk-overlay-container .modal-background {
  opacity: 0.85;
  background-color: var(--modal-bg-color);
}
.cdk-overlay-container .mat-tooltip {
  max-width: 200px;
  background: linear-gradient(90deg, var(--transparent-color) 0, var(--transparent-color) 26px, var(--tooltip-background) 26px, var(--tooltip-background));
  min-height: 20px;
  height: auto;
  margin-left: 18px;
  width: auto;
  border-radius: 0;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  font-family: var(--default-font);
  font-size: 10px;
  font-weight: var(--font-medium);
  color: var(--tooltip-text-color);
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  word-break: break-word;
  text-transform: uppercase;
  opacity: 1;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cdk-overlay-container .mat-tooltip:not(.my-tooltip) {
  display: none;
}
.cdk-overlay-container .mat-tooltip::before {
  content: "";
  width: 26px;
  height: 26px;
  background: var(--tooltip-background);
  z-index: -1;
  margin-left: 6px;
  transform: rotate(45deg);
  border-radius: 5px;
}
.cdk-overlay-container .mat-tooltip.no-arrow {
  border-radius: 5px;
  background: var(--tooltip-background);
}
.cdk-overlay-container .mat-tooltip.no-arrow::before {
  display: none;
}
.cdk-overlay-container .mat-tooltip.hint {
  text-transform: none;
  text-align: left;
  padding: 10px;
  letter-spacing: 0;
  line-height: initial;
}
.cdk-overlay-container .mat-menu-panel.variant {
  margin-right: 175px;
  margin-top: -25px;
}

.option-menu {
  width: 100%;
  background: var(--transparent-color);
  margin-right: 20px;
  min-width: 220px !important;
  transition: 1s margin-right;
  box-shadow: none;
  max-width: unset !important;
}
.option-menu .mat-menu-content:not(:empty) {
  padding: 0 0 3px 0;
  display: flex;
  flex-direction: column;
  margin: 10px 35px 10px 10px;
  border-radius: 4px;
}
.option-menu .mat-menu-content:not(:empty) .arrow-container {
  height: 20px;
  display: flex;
  background: var(--transparent-color);
  position: relative;
}
.option-menu .mat-menu-content:not(:empty) .arrow-container .rectangle {
  height: 100%;
  width: 100%;
  background: var(--mat-option-background-color);
  border-radius: 10px 0 0 0;
  box-shadow: -1px -2px 5px -2px rgba(0, 0, 0, 0.14);
  z-index: 2;
  cursor: pointer;
}
.option-menu .mat-menu-content:not(:empty) .arrow-container .arrow {
  position: absolute;
  right: -25px;
  width: 0;
  height: 0;
  background: var(--transparent-color);
  border-bottom: 20px solid var(--transparent-color);
  border-left: 25px solid var(--mat-option-background-color);
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.14));
}
.option-menu .mat-menu-content:not(:empty) .option-container {
  box-shadow: 0px -1px 9px 1px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  border-bottom: 5px solid var(--option-menu-extrusion-color);
  border-radius: 10px;
  background: var(--option-menu-extrusion-color);
  width: 100%;
}
.option-menu .mat-menu-content:not(:empty) .option-container .option {
  background: var(--mat-option-background-color);
  border: none;
  cursor: pointer;
  user-select: none;
  color: var(--grid-option-text-color);
  padding: 10px 15px;
  transition: var(--standard-transition);
}
@media all and (hover: none) and (pointer: coarse) {
  .option-menu .mat-menu-content:not(:empty) .option-container .option {
    margin: -1px;
  }
}
@media (hover: hover) and (pointer: fine) {
  .option-menu .mat-menu-content:not(:empty) .option-container .option:hover {
    color: var(--primary-color) !important;
  }
}
.option-menu .mat-menu-content:not(:empty) .option-container .option.delete {
  color: var(--option-menu-item-color-erase);
}
.option-menu .mat-menu-content:not(:empty) .option-container .option:first-of-type {
  padding-top: 0;
}
.option-menu .mat-menu-content:not(:empty) .option-container .option:last-of-type {
  border-radius: 0 0 10px 10px;
}
.option-menu .mat-menu-content:not(:empty) .option-container .option img {
  max-width: 100%;
}

.mat-select-panel {
  border-radius: 10px !important;
  background: var(--mat-option-background-color);
}
.mat-select-panel::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.mat-select-panel::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: var(--gray-color-09);
  box-shadow: none;
}
.mat-select-panel::-webkit-scrollbar-track {
  background-color: var(--transparent-color);
  border-radius: 10px;
  background: var(--transparent-color);
}
.mat-select-panel .mat-option .mat-pseudo-checkbox {
  background: var(--white-color);
}
.mat-select-panel .mat-option .mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
  background: var(--primary-color);
}
.mat-select-panel .mat-option .mat-option-text {
  font-family: var(--default-font);
  font-size: 14px;
  font-weight: var(--font-regular);
  color: var(--mat-option-text-color);
  line-height: 16px;
  letter-spacing: inherit;
  font-style: normal;
  text-align: left;
  user-select: none;
}
.mat-select-panel .mat-option.mat-selected .mat-option-text {
  font-family: var(--default-font);
  font-weight: var(--font-regular);
}
.mat-select-panel .mat-option.indent-left {
  padding-left: 32px;
}

@media print {
  .mat-nav-list {
    display: none !important;
  }
  editor-header {
    display: none;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 50000000000s;
}

/* items moving away to make room for drop */
.cdk-drop-list-dragging .mat-tree-node:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* item being dropped */
.cdk-drag-animating {
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

/* GENERALS */
/* SPECIFIC */
.disable-block {
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 999;
  background: var(--white-color);
  opacity: 0.7;
  bottom: 0;
  left: 0;
}

/* NUOVI DAL 2023 */
mat-tree-node app-icon {
  color: var(--light-gray);
  font-size: 12px;
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}
mat-tree-node app-icon:not(.arrow) {
  display: none;
}
mat-tree-node app-icon.arrow {
  transform: rotate(-90deg);
}
mat-tree-node .node-name {
  padding: 0 10px;
  width: 100%;
}
mat-tree-node .node-name .alert {
  color: var(--primary-color);
}
mat-tree-node .active {
  color: var(--primary-color);
}
mat-tree-node mat-checkbox {
  margin-top: 5px;
  margin-right: 20px;
}
mat-tree-node mat-checkbox.mat-checkbox-indeterminate::ng-deep .mat-checkbox-background {
  background: var(--brand-color-10);
}
mat-tree-node mat-checkbox.mat-checkbox-indeterminate::ng-deep .mat-checkbox-background::before {
  content: "";
  width: 7px;
  height: 1px;
  background: var(--white-color);
  position: absolute;
}
mat-tree-node mat-checkbox.mat-checkbox-checked::ng-deep .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background {
  background: var(--brand-color-10) !important;
}
mat-tree-node mat-checkbox::ng-deep .mat-checkbox-layout {
  display: flex;
  align-items: center;
}
mat-tree-node mat-checkbox::ng-deep .mat-checkbox-layout .mat-checkbox-inner-container {
  width: 13px;
  height: 13px;
  border-radius: 3px;
  border: 1px solid var(--gray-color-13);
  background: var(--white-color);
  margin: 0 10px 0 0;
  display: flex;
}
mat-tree-node mat-checkbox::ng-deep .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-ripple {
  display: none;
}
mat-tree-node mat-checkbox::ng-deep .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-frame {
  display: none;
}
mat-tree-node mat-checkbox::ng-deep .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background {
  border-radius: 0;
}
mat-tree-node mat-checkbox::ng-deep .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-background .mat-checkbox-mixedmark {
  display: none;
}
mat-tree-node mat-checkbox::ng-deep .mat-checkbox-layout .mat-checkbox-label {
  width: auto;
  max-width: 200px;
  font-family: var(--default-font);
  font-size: 14px;
  font-weight: var(--font-regular);
  color: var(--brand-color-09);
  line-height: 150%;
  letter-spacing: 0;
}
mat-tree-node mat-slide-toggle {
  margin-top: 5px;
  height: 40px;
}
mat-tree-node mat-slide-toggle.mat-checked::ng-deep .mat-slide-toggle-bar {
  background: var(--brand-color-10);
}
mat-tree-node mat-slide-toggle.mat-checked::ng-deep .mat-slide-toggle-bar .mat-slide-toggle-thumb-container {
  transform: translate3d(34px, 0, 0);
}
mat-tree-node mat-slide-toggle.mat-checked::ng-deep .mat-slide-toggle-bar .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
  background: var(--white-color);
}
mat-tree-node mat-slide-toggle.mat-checked::ng-deep .mat-slide-toggle-bar .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb::before {
  content: "7";
  color: var(--brand-color-10);
}
mat-tree-node mat-slide-toggle::ng-deep .mat-slide-toggle-bar {
  width: 70px;
  height: 36px;
  border-radius: 18px;
  background: var(--gray-color-03);
  display: flex;
  align-items: center;
}
mat-tree-node mat-slide-toggle::ng-deep .mat-slide-toggle-bar input {
  display: none;
}
mat-tree-node mat-slide-toggle::ng-deep .mat-slide-toggle-bar .mat-slide-toggle-thumb-container {
  width: 26px;
  height: 26px;
  top: unset;
  left: 5px;
}
mat-tree-node mat-slide-toggle::ng-deep .mat-slide-toggle-bar .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb {
  width: 26px;
  height: 26px;
  border-radius: 100%;
  background: var(--brand-color-10);
  display: flex;
  justify-content: center;
  align-items: center;
}
mat-tree-node mat-slide-toggle::ng-deep .mat-slide-toggle-bar .mat-slide-toggle-thumb-container .mat-slide-toggle-thumb::before {
  content: "g";
  font-family: var(--datalean-icon-font);
  font-size: 8px;
  color: var(--white-color);
  cursor: pointer;
  text-transform: none;
}
mat-tree-node mat-slide-toggle::ng-deep .mat-slide-toggle-bar .mat-slide-toggle-thumb-container .mat-slide-toggle-ripple {
  display: none;
}
mat-tree-node mat-slide-toggle::ng-deep .mat-slide-toggle-content {
  font-family: var(--default-font);
  font-size: 14px;
  font-weight: var(--font-regular);
  color: var(--brand-color-09);
  line-height: 150%;
  letter-spacing: inherit;
}
mat-tree-node.cdk-drag-preview {
  box-shadow: 5px 5px 15px var(--light-gray);
  background: var(--white-color);
  border-radius: 5px;
}
mat-tree-node.cdk-drag-preview .mat-checkbox.mat-checkbox-checked .mat-slide-toggle-thumb,
mat-tree-node.cdk-drag-preview .mat-checkbox.mat-checkbox-checked .mat-checkbox-background, mat-tree-node.cdk-drag-preview .mat-checkbox.mat-checked .mat-slide-toggle-thumb,
mat-tree-node.cdk-drag-preview .mat-checkbox.mat-checked .mat-checkbox-background {
  background: var(--checkbox-fill-color);
}
mat-tree-node.cdk-drag-preview .mat-checkbox .mat-slide-toggle-thumb-container {
  top: 0;
}
mat-tree-node.cdk-drag-preview .mat-checkbox .mat-slide-toggle-bar {
  background: var(--checkbox-border-color);
  height: 20px;
  border-radius: 20px;
}
mat-tree-node.cdk-drag-preview .mat-checkbox .mat-checkbox-frame {
  border-color: var(--checkbox-border-color);
}

.cdk-drag-preview.mat-chip {
  border-radius: 0;
  background: var(--transparent-color);
  padding: 15px 5px;
}
.cdk-drag-preview.mat-chip::after {
  background: var(--transparent-color) !important;
}
.cdk-drag-preview.mat-chip:active {
  box-shadow: unset !important;
}
.cdk-drag-preview.mat-chip .image-container {
  height: 120px;
  padding: 5px;
}
.cdk-drag-preview.mat-chip .image-container .chip-preview-container {
  height: 100%;
  object-fit: cover;
  object-position: center;
  max-width: 100%;
}
.cdk-drag-preview.mat-chip .image-container .bottom-bar {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  bottom: 0;
  box-sizing: border-box;
}
.cdk-drag-preview.mat-chip .image-container .bottom-bar span {
  overflow: hidden;
  height: 20px;
  flex: 1;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
}
.cdk-drag-preview.mat-chip .image-container .bottom-bar app-icon {
  color: var(--red-color);
  font-size: 8px;
}
.cdk-drag-preview.mat-chip .image-container.not-image {
  background-size: auto;
}
.cdk-drag-preview.mat-chip span {
  display: flex;
  align-items: center;
}

ion-alert .alert-wrapper .alert-button-group button:last-child {
  border: none !important;
}
ion-alert .alert-wrapper .alert-button-group button:last-child span {
  color: var(--option-menu-item-color-erase) !important;
}

container-editor {
  display: flex;
  flex-flow: row wrap !important;
}

container-editor > .cdk-drop-list {
  width: 100%;
}
container-editor > .cdk-drop-list > .cdk-drag {
  display: flex;
  flex-flow: row wrap;
}
container-editor > .cdk-drop-list > .cdk-drag > command-list {
  width: 100% !important;
}
container-editor > .cdk-drop-list > .cdk-drag > command-list .command-list-container {
  top: 40px !important;
}

.ratio-0,
.ratio-10,
.ratio-15,
.ratio-20,
.ratio-25,
.ratio-33,
.ratio-50,
.ratio-66,
.ratio-75,
.ratio-80,
.ratio-85,
.ratio-90,
.ratio-100,
.ratio-undefined {
  box-sizing: border-box;
  padding-right: 10px !important;
  padding-top: 10px;
}

.ratio-10 {
  width: 10% !important;
}

.ratio-15 {
  width: 15% !important;
}

.ratio-20 {
  width: 20% !important;
}

.ratio-25 {
  width: 25% !important;
}

.ratio-33 {
  width: 33.333% !important;
}

.ratio-50 {
  width: 50% !important;
}

.ratio-66 {
  width: 66.666% !important;
}

.ratio-75 {
  width: 75% !important;
}

.ratio-80 {
  width: 80% !important;
}

.ratio-85 {
  width: 85% !important;
}

.ratio-90 {
  width: 90% !important;
}

.ratio-0,
.ratio-100,
.ratio-undefined {
  width: 100% !important;
}

app-modal .modal-content .spinner-container {
  border-radius: 20px;
}

/* TOOLTIP */