@use '@angular/material' as mat;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import './assets/fonts/fonts.css';
@import "mixins";
@import 'mobile';

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
//generati da http://mcg.mbitson.com
$datalean-primary-palette: (
  50 : #e0e6ef,
  100 : #b3bfd7,
  200 : #8095bd,
  300 : #4d6ba2,
  400 : #264b8e,
  500 : #002b7a,
  600 : #002672,
  700 : #002067,
  800 : #001a5d,
  900 : #00104a,
  A100 : #7e8dff,
  A200 : #4b61ff,
  A400 : #1834ff,
  A700 : #001ffd,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$datalean-fe-primary: mat.define-palette($datalean-primary-palette);
$datalean-fe-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$datalean-fe-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$datalean-fe-theme: mat.define-light-theme($datalean-fe-primary, $datalean-fe-accent, $datalean-fe-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($datalean-fe-theme);

/* You can add global styles to this file, and also import other style files */

* {
  font-family: var(--default-font);
  font-weight: var(--font-regular);
}

html,
body {
  height: 100%;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

app-root {
  width: 100%;
}

:focus {
  outline: unset;
}

:root {
  /* nuovi */
  --default-font:                 'Segma', sans-serif;
  --datalean-icon-font:           'DataLean Font Icon';
  --smartCo-icon-font:            'Smart Community';

  --font-thin:                    100;
  --font-extralight:              200;
  --font-light:                   300;
  --font-regular:                 400;
  --font-medium:                  500;
  --font-semibold:                600;
  --font-bold:                    700;
  --font-black:                   900;

  --white-color:                  #FFFFFF;
  --black-color:                  #000000;
  --black-color-05:               #00000080;
  --transparent-color:            #FFFFFF00;
  --error-color:                  #FF0000;

  --brand-color-01:               #0048CD; //rgba(0, 72, 205, 1) //primary
  --brand-color-02:               #82BDFF; //primary-light
  //--brand-color-03:               #;
  --brand-color-04:               #38424C;
  --brand-color-05:               #DB202F;
  --brand-color-06:               #AB00EB;
  --brand-color-07:               #70009A; //secondary
  --brand-color-08:               #010F34; //restore password uguale ad auth
  --brand-color-09:               #4A4A4A; //testo
  --brand-color-10:               #2B82FF; //blu per buttons
  --brand-color-11:               #002B7A; //blu

  --gray-color-01:                #A4A4A4;
  --gray-color-02:                #ADADAD;
  --gray-color-03:                #E6E6E6;
  --gray-color-04:                #CCCCCC;
  --gray-color-05:                #D8D8D8;
  --gray-color-06:                #DDDDDD;
  --gray-color-07:                #F5F5F5;
  --gray-color-08:                #FAFAFA;
  --gray-color-09:                #979797;
  --gray-color-10:                #757575;
  --gray-color-11:                #777777;
  --gray-color-12:                #F1F1F1;
  --gray-color-13:                #9B9B9B;

  --logo-datalean:                url('./assets/images/logo-datalean.png');
  --standard-transition:          all 0.3s ease-out;

  /* precedenti */
  --button-blue-color:            var(--standard-button-color);
  --red-color:                    #ff3d00;
  --lighter-blue:                 #82bdff;
  --row-separator-color:          rgba(0, 0, 0, 0.12);
  --lighter-gray:                 rgba(232, 232, 232, 0.3);
  --light-gray:                   #E6E6E6; //rgba(230, 230, 230, 1)
  --medium-gray:                  #757575; //rgba(117, 117, 117, 1)
  --medium-dark-grey:             #4A494A; //rgba(74, 73, 74, 1)
  --green-color:                  #42bd41;
  --orange-color:                 #FF9A00;
  --purple-light-color:           #B000F2;
  --purple-color:                 #70009A;
  --primary-opacity:              #337BFF;

  /* COMMUNICATIONS */
  --communication-details-date-color: var(--standard-button-color);
  --communication-details-title-color: var(--primary-toggle-color);
  --communication-details-text-color: var(--form-text);
  --communication-details-attachment-title-color: var(--form-text);
  --communication-details-attachment-detail-color: var(--medium-gray);
  --communication-details-confirmation-background-color: var(--light-gray);
  --communication-details-confirmation-text-color: var(--form-text);
  --communication-details-attachment-icon-color: var(--primary-color);
  --communication-preview-background: rgba(58, 62, 70, 1);
  --communication-preview-button-background: var(--medium-gray);
  --communication-preview-qrcode-image: url('./assets/images/qrcode.png');

  --disabled-label: var(--light-gray);
  --disabled-background: rgba(245, 245, 245, 1);

  --login-background-error: rgb(95, 27, 87);

  /* MAT-NAV-LIST */
  --transparent-background: var(--transparent-color);
  --nav-menu-width: 120px;
  --nav-menu-background-width: 45px;
  --nav-menu-background-width-expanded: 175px;

  --form-label-color: rgba(119, 119, 119, 1);
  --form-checkbox-color: rgba(74, 73, 74, 1);

  --folder-image-local-url: url('./assets/images/media-library/folder.png');
  --file-placeholder-image-local-url: url('./assets/images/media-library/file.png');

  --icon-editor-html-snippet: url('./assets/images/icn-snippet.svg');
  --icon-editor-html-snippet-hover: url('./assets/images/icn-snippet-hover.svg');
  --icon-editor-html-colorfill: url('./assets/images/icn-colorfill.svg');
  --icon-editor-html-colorfill-hover: url('./assets/images/icn-colorfill-hover.svg');
  --icon-editor-html-source: url('./assets/images/icn-source.svg');
  --icon-editor-html-source-hover: url('./assets/images/icn-source-hover.svg');
}

body {
  background-color: var(--background-color);
  overflow: hidden;
  margin: 0;

  input {
    outline: none;
  }

  table.cdk-table,
  .mat-paginator {
    background-color: var(--background-color);
  }

  a {
    text-decoration: none;
  }

  .cdk-drag-preview {

    &.hotspot {
      width: 30px;
      height: 30px;
      background: var(--primary-color);
      border-radius: 100%;
      position: absolute;
      @include flex-center;
      z-index: 999;
      color: var(--white-color);
      cursor: pointer;
    }

  }

  > container-field.cdk-drag-preview,
  > container-field.cdk-drag-dragging,
  > product-field-editor.cdk-drag-preview,
  > product-field-editor.cdk-drag-dragging {
    opacity: 0.5 !important;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
  }

  container-field.cdk-drag-preview,
  container-field.cdk-drag-dragging,
  product-field-editor.cdk-drag-preview,
  product-field-editor.cdk-drag-dragging {

    .container-with-command-list {

      command-list {
        display: none;
      }

    }

  }

}

.mat-calendar-body-selected {
  background-color: var(--button-blue-color) !important;;
}

.mat-body,
.mat-body-1,
.mat-typography,
.mat-optgroup-label {
  font: 300 12px/20px var(--default-font);
  letter-spacing: 0.5px;
}

@media (hover: none) and (pointer: coarse) {

  .cdk-overlay-container {

    .mat-tooltip {
      display: none !important;
    }

  }

}

.cdk-overlay-container {
  display: flex;

  .cdk-overlay-pane {
    width: auto !important;

    .mat-autocomplete-panel-above,
    .mat-autocomplete-panel {
      border-radius: 10px !important;
      background: var(--mat-option-background-color);
      @include scroll-bar;

      .mat-selected {

        .mat-option-text {
          font-family: var(--default-font);
          font-weight: var(--font-regular);
        }

      }

      .mat-option-text {
        @include font-style(14px, var(--font-regular), var(--mat-option-text-color), inherit, 16px);
        font-style: normal;
        text-align: left;
        user-select: none;
      }

      mat-option.indent-left {
        padding-left: 32px;
      }

    }

    &.comparator-modal {

      app-modal {
        border-radius: 0;
        border-bottom: 0;
      }

    }

  }

  .cdk-overlay-connected-position-bounding-box {

    #cdk-overlay-0 {
      //top: 28px !important; //da capire
    }

  }

  .modal-background {
    opacity: .85;
    background-color: var(--modal-bg-color);
  }

  .mat-tooltip {
    max-width: 200px;
    background: linear-gradient(90deg, var(--transparent-color) 0, var(--transparent-color) 26px, var(--tooltip-background) 26px, var(--tooltip-background));
    min-height: 20px;
    height: auto;
    margin-left: 18px;
    width: auto;
    border-radius: 0;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    @include font-style(10px, var(--font-medium), var(--tooltip-text-color), 0, 20px);
    text-align: left;
    word-break: break-word;
    text-transform: uppercase;
    opacity: 1;
    z-index: 2;
    @include flex-center;

    &:not(.my-tooltip) {
      display: none;
    }

    &::before {
      content: "";
      width: 26px;
      height: 26px;
      background: var(--tooltip-background);
      z-index: -1;
      margin-left: 6px;
      transform: rotate(45deg);
      border-radius: 5px;
    }

    &.no-arrow {

      &::before {
        display: none;
      }

      border-radius: 5px;
      background: var(--tooltip-background);
    }

    &.hint {
      text-transform: none;
      text-align: left;
      padding: 10px;
      letter-spacing: 0;
      line-height: initial;
    }

  }

  .mat-menu-panel.variant {
    margin-right: 175px;
    margin-top: -25px;
  }

}

.option-menu {
  width: 100%;
  background: var(--transparent-color);
  margin-right: 20px;
  min-width: 220px !important;
  transition: 1s margin-right;
  box-shadow: none;
  max-width: unset !important;

  .mat-menu-content:not(:empty) {
    padding: 0 0 3px 0;
    display: flex;
    flex-direction: column;
    margin: 10px 35px 10px 10px;
    border-radius: 4px;

    .arrow-container {
      height: 20px;
      display: flex;
      background: var(--transparent-color);
      position: relative;

      .rectangle {
        height: 100%;
        width: 100%;
        background: var(--mat-option-background-color);
        border-radius: 10px 0 0 0;
        box-shadow: -1px -2px 5px -2px rgba(0, 0, 0, 0.14);
        z-index: 2;
        cursor: pointer;
      }

      .arrow {
        position: absolute;
        right: -25px;
        width: 0;
        height: 0;
        background: var(--transparent-color);
        border-bottom: 20px solid var(--transparent-color);
        border-left: 25px solid var(--mat-option-background-color);
        filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.14));
      }

    }

    .option-container {
      box-shadow: 0px -1px 9px 1px rgba(0, 0, 0, 0.14);
      display: flex;
      flex-direction: column;
      border-bottom: 5px solid var(--option-menu-extrusion-color);
      border-radius: 10px;
      background: var(--option-menu-extrusion-color);
      width: 100%;

      .option {
        background: var(--mat-option-background-color);
        border: none;
        cursor: pointer;
        user-select: none;
        color: var(--grid-option-text-color);
        padding: 10px 15px;
        transition: var(--standard-transition);

        @media all and (hover: none) and (pointer: coarse) {
          margin: -1px;
        }

        @media (hover: hover) and (pointer: fine) {

          &:hover {
            color: var(--primary-color) !important;
          }

        }

        &.delete {
          color: var(--option-menu-item-color-erase);
        }

        &:first-of-type {
          padding-top: 0;
        }

        &:last-of-type {
          border-radius: 0 0 10px 10px;
        }

        img {
          max-width: 100%;
        }

      }

    }

  }

}

.mat-select-panel {
  border-radius: 10px !important;
  background: var(--mat-option-background-color);
  @include scroll-bar-dark;

  .mat-option {

    .mat-pseudo-checkbox {
      background: var(--white-color);

      &.mat-pseudo-checkbox-checked {
        background: var(--primary-color);
      }

    }

    .mat-option-text {
      @include font-style(14px, var(--font-regular), var(--mat-option-text-color), inherit, 16px);
      font-style: normal;
      text-align: left;
      user-select: none;
    }

    &.mat-selected {

      .mat-option-text {
        font-family: var(--default-font);
        font-weight: var(--font-regular);
      }

    }

    &.indent-left {
      padding-left: 32px;
    }

  }

}

@media print {

  .mat-nav-list {
    display: none !important;
  }

  editor-header {
    display: none;
  }

  //REMOVE TOP AND BOTTOM ROWS
  // @page {
  //   size: auto;
  //   margin-top: 0;
  //   margin-bottom: 0;
  // }

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 50000000000s;
}

/* items moving away to make room for drop */
.cdk-drop-list-dragging .mat-tree-node:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* item being dropped */
.cdk-drag-animating {
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1);
}

@import "mixins";

mat-tree-node {

  app-icon {
    color: var(--light-gray);
    font-size: 12px;
    width: 23px;
    height: 23px;
    @include flex-center;

    &:not(.arrow) {
      display: none;
    }

    &.arrow {
      transform: rotate(-90deg);
    }

  }

  .node-name {
    padding: 0 10px;
    width: 100%;

    .alert {
      color: var(--primary-color);
    }

  }

  .active {
    color: var(--primary-color)
  }

  @include datalean-checkbox;

  &.cdk-drag-preview {
    box-shadow: 5px 5px 15px var(--light-gray);
    background: var(--white-color);
    border-radius: 5px;

    .mat-checkbox {

      &.mat-checkbox-checked,
      &.mat-checked {

        .mat-slide-toggle-thumb,
        .mat-checkbox-background {
          background: var(--checkbox-fill-color);
        }

      }

      .mat-slide-toggle-thumb-container {
        top: 0;
      }

      .mat-slide-toggle-bar {
        background: var(--checkbox-border-color);
        height: 20px;
        border-radius: 20px;
      }

      .mat-checkbox-frame {
        border-color: var(--checkbox-border-color);
      }

    }

  }

}

.cdk-drag-preview {

  &.mat-chip {
    border-radius: 0;
    background: var(--transparent-color);
    padding: 15px 5px;

    &::after {
      background: var(--transparent-color) !important;
    }

    &:active {
      box-shadow: unset !important;
    }

    .image-container {
      height: 120px;
      padding: 5px;

      .chip-preview-container {
        height: 100%;
        object-fit: cover;
        object-position: center;
        max-width: 100%;
      }

      .bottom-bar {
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        bottom: 0;
        box-sizing: border-box;

        span {
          overflow: hidden;
          height: 20px;
          flex: 1;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
        }

        app-icon {
          color: var(--red-color);
          font-size: 8px;
        }

      }

      &.not-image {
        background-size: auto;
      }

    }

    span {
      display: flex;
      align-items: center;
    }

  }

}

ion-alert {

  .alert-wrapper {
    //background: var(--transparent-color) !important;
    //box-shadow: none !important;
    //.alert-head {
    //  display: none !important;
    //}

    .alert-button-group {
      //background: rgba(255, 255, 255, 0.7) !important;
      //padding: 0 !important;
      //border-radius: 20px!important;
      //display: flex;
      //flex-direction: column;

      button {
        //margin: 0 !important;
        //border-bottom: 1px solid var(--row-separator-color) !important;
        //background: var(--transparent-color) !important;
        //
        //span {
        //  width: auto !important;
        //  justify-content: start !important;
        //  color: var(--option-menu-item-color)!important;
        //}

        &:last-child {
          border: none !important;

          span {
            color: var(--option-menu-item-color-erase) !important;
          }

        }

      }

    }

  }

}

//[class^=ratio] { //dava fastidio in un punto, se non serve, cancellare
//
//  > field-editor {
//
//    >  .container-with-command-list {
//      padding-right: 10px;
//    }
//
//  }
//
//}

container-editor {
  display: flex;
  flex-flow: row wrap!important;
}

container-editor {

  > .cdk-drop-list {
    width: 100%;

    > .cdk-drag {
      display: flex;
      flex-flow: row wrap;

      > command-list {
        width: 100% !important;

        .command-list-container {
          top: 40px !important;
        }

      }

    }

  }

}

.ratio-0,
.ratio-10,
.ratio-15,
.ratio-20,
.ratio-25,
.ratio-33,
.ratio-50,
.ratio-66,
.ratio-75,
.ratio-80,
.ratio-85,
.ratio-90,
.ratio-100,
.ratio-undefined {
  box-sizing: border-box;
  padding-right: 10px !important;
  padding-top: 10px;
}

.ratio-10 {
  width: 10% !important;
}
.ratio-15 {
  width: 15% !important;
}
.ratio-20 {
  width: 20% !important;
}
.ratio-25 {
  width: 25% !important;
}
.ratio-33 {
  width: 33.333% !important;
}
.ratio-50 {
  width: 50% !important;
}
.ratio-66 {
  width: 66.666% !important;
}
.ratio-75 {
  width: 75% !important;
}
.ratio-80 {
  width: 80% !important;
}
.ratio-85 {
  width: 85% !important;
}
.ratio-90 {
  width: 90% !important;
}
.ratio-0,
.ratio-100,
.ratio-undefined {
  width: 100% !important;
}

app-modal {

  .modal-content {

    .spinner-container {
      border-radius: 20px;
    }

  }

}

// COMMENTANDO QUESTO IL LOADER SI VEDE IN TUTTA LA PAGINA, IN CASO NON SI VOGLIA SI DEVE CAPIRE COME RENDERE DINAMICA LA VARIABILE O LA CLASSE DENTRO
// AL app-main

//app-main {
//  .spinner-container {
//    width: calc(100% - var(--nav-menu-width)) !important;
//  }
//}

/* TOOLTIP */
//app-icon.mat-tooltip-trigger {
//  @include form-field-tooltip;
//}
